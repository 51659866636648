import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as turf from '@turf/turf';
import { QuestionModalComponent } from '../components/questionModal/questionModal.component';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GameService } from './game.service';
import { Router } from '@angular/router';
import Noty from 'noty';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LocaleService } from './locale.service';
import confetti from 'canvas-confetti';

@Injectable({
  providedIn: 'root',
})
export class GeoService {
  mapTouched = true;
  canLookup = true;
  modalActive = false;
  stillUpdating = false;
  lastMarkerNotyDropped = false;
  constructor(private modalService: NgbModal, private gameService: GameService,  private router: Router,  private http: HttpClient, private localeService: LocaleService) {}

  isQuestionNearby(questions, position, activePerks?) {
    if(activePerks){
      activePerks = _.cloneDeep(activePerks);
    }
    this.canLookup = false;
    let qDetect = false;
    let outsideCircle = false;

    if(this.gameService.activeGame.tourId.isBattleCircle && this.gameService.battleCircle.data.geometry && this.gameService.battleCircle.data.geometry.coordinates.length && position.lng && position.lat){
      if(!turf.booleanContains(turf.polygon(this.gameService.battleCircle.data.geometry.coordinates), turf.point([position.lng, position.lat]))){
         new Noty({
            type: 'error',
            text: this.localeService.getTrans("You are standing outside the battle circle. You've lost ") + (this.gameService.isPartnerGame ? 5 : 10) + this.localeService.getTrans(" points!"),
            killer: true
        }).show();
        outsideCircle = true;
        this.gameService.me.points -= (this.gameService.isPartnerGame ? 5 : 10) ;
        this.updateUser();
      }
    }
   

    if(this.gameService.endQuest.isVisible){
        const distance = turf.distance(
            turf.point([position.lng, position.lat]),
            turf.point([this.gameService.endQuest.coordinates[0], this.gameService.endQuest.coordinates[1]]),
            { units: 'kilometers' }
          );
          if (distance <= 0.03) {
            this.questionDetected({
              questionType: 'endPoi',
              ...this.gameService.endQuest,
              ...this.gameService.activeGame.tourId.endQuest
            }, null, activePerks);
            return false;
          }
    }

    _.forEach(questions, (question) => {
        const distance = turf.distance(
        turf.point([position.lng, position.lat]),
        turf.point([question.lng, question.lat]),
        { units: 'kilometers' }
      );
      if (distance <= (this.gameService.activeGame.tourId.isDynamicQuestionCreation ? 0.03 : 0.015)) {
        qDetect = true;
        this.questionDetected(question,null,activePerks);
        return false;
      }
    });


    /* if(!qDetect && !outsideCircle){
      this.dropNoty('info', this.localeService.getTrans("Well, there are no markers nearby."));
    } */

    if(!qDetect){
      this.canLookup = true;
    }
    
  }

  updatePubnubUser(){

  }

  calculateRanking(){
    this.gameService.me.ranking = _.findIndex(_.orderBy(this.gameService.activeGame.players, ['points'], ['desc']), (r)=>{
      return (r.name === this.gameService.me.name);
    })
  }

  doEndGame(reason){
    this.http.post(environment.baseUrl + 'games/endGame', { gameId: this.gameService.pageConfig.g, playerId: this.gameService.me._id }).subscribe((data: any) => { });
   
    if(this.gameService.activeGame.tourId && this.gameService.activeGame.tourId.isEndScreen){
      window.location.href = "https://jobverse-admin.azurewebsites.net/jobverse";
    }
    if (this.gameService.activeGame.isMuseum) {
      window.location.href = "https://jobverse-admin.azurewebsites.net/jobverse";
    } else {
      window.location.href = "https://jobverse-admin.azurewebsites.net/jobverse";
    }
  }

  endGame(reason, forcedClose?){
    const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }

    const endPOIQuestion = _.find(this.gameService.activeGame.quests, (qts)=>{return qts.isEndPOITimeline});

    if(endPOIQuestion && !forcedClose){
      this.doQuestion(endPOIQuestion);
    } else{
      if (this.gameService.activeGame.isEndPOI) {
        this.doEndGame(reason);
      } else {
        this.doEndGame(reason);
      }
    }
   
  }

  updateUserStress(){
    return this.http.post(environment.baseUrl + 'games/updatePlayerStress', {gameId: this.gameService.activeGame._id, player: this.gameService.me}).subscribe((data: any) => {});
  }

  getFeatureCenter() {
    let features = [];
    let featuresTurf;
    _.forEach(this.gameService.activeGame.quests, (q) => {
      features.push([q.lng, q.lat]);
    })
    featuresTurf = turf.points(features);
    let center = turf.center(featuresTurf);
    return center;
  }

  activateGameEndPOI() {

    if (this.gameService.activeGame.isEndPOI && !this.gameService.endQuest.isVisible) {
      let center = this.getFeatureCenter();
      this.gameService.endQuest.coordinates = center.geometry.coordinates;

      this.gameService.endQuest.isVisible = true;
      this.dropNoty('success', "<img src='./assets/images/pois/sh.png' width='30'>" + this.localeService.getTrans("Congrats! You've activated the safehouse! Now find it on the map and reach it before it's too late!"), 'safehouse')

    }
  }

  getNumberOfAvailableQuestions(){
     return this.gameService.activeGame.quests.length;
  }

  getUserByTeamName(teamName){
    return this.http.post(environment.baseUrl + 'games/getPlayerByTeamName', {gameId: this.gameService.activeGame._id, player: teamName}).subscribe((data: any) => {
      this.gameService.me = data;
      this.gameService.activeGame.quests = _.filter(this.gameService.originalQuests, (q)=>{
        return this.gameService.me.passedQuests.indexOf(q._id) === -1 && !q.isEndPoi
      }) 

   
     
        console.log('Number of available questions for start', this.getNumberOfAvailableQuestions())
          if(this.getNumberOfAvailableQuestions() === 0){
            this.endGame('end');
          }
         /*  if(_.filter(this.gameService.activeGame.quests, (quest)=>{return quest.isQuestion}).length === 0){
            this.endGame('end');
          } */
        
      
      
    });
  }

  updateUserIfOK(){
    if(!this.stillUpdating){
      this.updateUserLocation();
    }
  }

  updateUserLocation(){
    return this.http.post(environment.baseUrl + 'games/updatePlayerLocation', {gameId: this.gameService.activeGame._id, player: this.gameService.me}).subscribe((data: any) => {
    });
  }

  updateUser(canLookup?, wasCorrect?, passedTime?){
   
    if(!this.modalActive || canLookup){
      this.stillUpdating = true;
      return this.http.post(environment.baseUrl + 'games/updatePlayerOne', {gameId: this.gameService.activeGame._id, player: this.gameService.me, wasCorrect, passedTime}).subscribe((data: any) => {
      
        this.gameService.me = data.data;
       
        this.gameService.activeGame.quests = _.filter(this.gameService.originalQuests, (q)=>{
          return this.gameService.me.passedQuests.indexOf(q._id) === -1 && !q.isEndPoi
        }); 
        console.log(this.gameService.activeGame.quests)
        console.log('1')
        console.log(_.find(this.gameService.activeGame.quests, (q)=>{
          return (!q.isEndPoi && !q.isEndPOITimeline)
        }))
     /*    if(!_.find(this.gameService.activeGame.quests, (q)=>{
          return (!q.isEndPoi && !q.isEndPOITimeline)
        })){ */
          console.log('2')
         
            /* if(_.filter(this.gameService.activeGame.quests, (quest)=>{return quest.isQuestion}).length === 0){
              this.endGame('end');
            } */

           
            if(this.getNumberOfAvailableQuestions() === 0){
              console.log('ENDING GAME')
             this.endGame('end');
            
          }
        
  
        this.calculateRanking();
        setTimeout(()=>{
          this.stillUpdating = false;
          if(canLookup){
            this.canLookup = true;
          }
        },3000);
      
      }); 
    }
   
  }
  dropSwal(title, content, type) {
    Swal.fire({
      title: title,
      text: content,
      icon: type,
      confirmButtonText: 'Ok',
      confirmButtonColor: '#4E3B74',
      heightAuto: false,
    });
  }
  getCorrectAnswer(question) {
    if (question.type === '0') {
      return _.filter(question.answers, (o) => {
        return o.isTrue;
      })[0].answer;
    } else if (question.type === '1' || question.type === '2') {
      return question.answers[0].answer;
    }
  }

  scrollTo(id){
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth'
    });
  }


  dropNoty(type, text, clickEvent?){
    new Noty({
      type: type,
      text: text,
      killer: true,
      callbacks: {
        onClick: ()=>{
          if(clickEvent && clickEvent === 'scrollToBackpack'){
            this.scrollTo('backpacks');
          }
        }
      }
     
  }).show();
  }

  dropConfetti(){
    confetti();
  }

  doQuestion(question, isIndoorMode?, activePerks?){
    if(activePerks){
      activePerks = _.cloneDeep(activePerks);
    }
    if(this.gameService.me.passedQuests.indexOf(question._id) === -1){
      this.gameService.me.passedQuests.push(_.clone(question._id));
      this.modalActive = true;

      if(activePerks && activePerks.bomb){
        this.gameService.geoServiceSubject$.next({bomb: true})
      }
    
      const instance = this.modalService.open(QuestionModalComponent, {
        backdrop: 'static',
        keyboard: false,
        centered: true
      });
      instance.componentInstance.question = _.cloneDeep(question);
      instance.result.then((result) => {
        instance.close();
        let wasCorrect = 0
        let passedTime = 0;
        if(result.type !== 'closeWithoutEvent'){
          if (result.type === 'correct') {
            wasCorrect = 1;
            passedTime = result.time;
            let points = result.data.points;


          /*   if(result.data.colorCode){
              const numberOfQuestionsWithColor = _.sumBy(_.filter(this.gameService.activeStoredGame.quests, (q)=>{
                return q.colorCode;
              }), 'points');
              const pointsOfMyColor = _.sumBy(_.filter(this.gameService.activeStoredGame.quests, (q)=>{
                return q.colorCode === this.gameService.me.colorCode
              }), 'points');
              if(pointsOfMyColor === 0){
                points = points;
              } else{
                points = Math.round((pointsOfMyColor / numberOfQuestionsWithColor)*points);
              }
            }  */

            if(this.gameService.interimSettings.doubleActive){
              this.gameService.me.points += points*2;
            } else if(activePerks && activePerks.bomb){
              this.gameService.me.points += Math.round(points/2);
            } else{
              this.gameService.me.points += points;
            }
            Swal.fire({
              title: this.localeService.getTrans('Correct answer'),
              text: this.localeService.getTrans("Correct answer! You have earned ") + ((activePerks && activePerks.bomb) ? Math.round(points):  points) + this.localeService.getTrans(" points!"),
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4E3B74',
              heightAuto: false,
            }).then((result) => {

              this.dropConfetti();
              if(question.isEndPOITimeline){
                this.endGame('endTimelinePOI', true);
              }

              if (result.isConfirmed) {
                if(isIndoorMode){
                  this.questionDetected(this.gameService.activeGame.quests[0], true, activePerks);
                }
              } 
            })
  
            this.gameService.interimSettings.doubleActive = false;
           
           
          } else if (result.type === 'incorrect') {
            wasCorrect = 2;
            Swal.fire({
              title: 'Ouuuch',
              text: this.localeService.getTrans('The correct answer would be: ') +  this.getCorrectAnswer(result.data),
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4E3B74',
              heightAuto: false,
            }).then((result) => {
              if (result.isConfirmed) {
                if(question.isEndPOITimeline){
                  this.endGame('endTimelinePOI', true);
                }
                if(isIndoorMode){
                  this.questionDetected(this.gameService.activeGame.quests[0], false, activePerks);
                }
              } 
            })
  
            this.gameService.interimSettings.doubleActive = false;
          } else if (result.type === 'outOfTime') {
            wasCorrect = 3;
            Swal.fire({
              title: this.localeService.getTrans('The time is up'),
              text: this.localeService.getTrans('The time is up'),
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4E3B74',
              heightAuto: false,
            }).then((result) => {
              if (result.isConfirmed) {
                if(question.isEndPOITimeline){
                  this.endGame('endTimelinePOI', true);
                }
                if(isIndoorMode){
                  this.questionDetected(this.gameService.activeGame.quests[0], true, activePerks);
                }
              } 
            })
            this.gameService.interimSettings.doubleActive = false;
          } else if(result.type === 'backpack'){
            this.dropNoty('info', this.localeService.getTrans("A new item has been added to your backpack!"), "scrollToBackpack")
  
            this.gameService.me.backpack.push(result.data.mediaUrl)
          } else if(result.type === 'endPoiSucess'){
            this.dropSwal(
              this.localeService.getTrans('Correct answer'),
              this.localeService.getTrans('Your rewards: 1000 points!'),
              'success'
            );
            wasCorrect = 1;
            this.gameService.me.points += 1000;
            this.gameService.me.isFinished = true;
          } else if(result.type === 'endPoiNotSucess'){
            wasCorrect = 2;
            this.dropSwal(
              this.localeService.getTrans('Incorrect answer'),
              this.localeService.getTrans('Try again'),
              'error'
            );
          }
  
          if(this.gameService.activeGame.tourId.isQR){
            this.gameService.me = _.merge(this.gameService.me, {position: {
                lng: question.lng,
                lat: question.lat
              }
            }) 
          }
  
          if(this.gameService.activeGame.quests){
            _.remove(this.gameService.activeGame.quests, (x)=>{
                return x._id === question._id;
            })
          } 

       
          this.updateUser(true, wasCorrect, passedTime);
          
        } else{
          this.canLookup = true;
        }

        this.modalActive = false
      });
    }
  }

  vibrate(){
    if(this.mapTouched){
      /* if('vibrate' in navigator){
        navigator.vibrate(500);
      } */
    }
  }

  questionDetected(question, isIndoorMode?, activePerks?) {

    if(activePerks){
      activePerks = _.cloneDeep(activePerks);
    }
    if(question.isEndPoi) {

      let noOfPassedQuestions = 0;
      let questions = _.filter(this.gameService.activeGame.quests, (quest)=>{return quest.isQuestion})
      _.forEach(this.gameService.me.passedQuests, (i)=>{
        if(questions.indexOf(i) !== -1){
          noOfPassedQuestions++;
        }
      })

      if(noOfPassedQuestions === questions.length-1){
        this.doQuestion(question, isIndoorMode, activePerks);
      } else{
        this.dropNoty('warning','This point will be available when you collected all the other questions on the map.');
      }

    } else{
      this.doQuestion(question, isIndoorMode, activePerks);
    }

    
  }
}
