import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '../services/utils.service';
import { EmojiServiceService } from '../services/emoji-service.service';
import { MobileAppService } from '../services/mobile-app.service';

@Component({
  selector: 'app-museum-leaderboard',
  templateUrl: './museum-leaderboard.component.html',
  styleUrls: ['./museum-leaderboard.component.scss'],
})
export class MuseumLeaderboardComponent implements OnInit {
  partner;
  hofs = [];
  pageConfig = {
    activeTab: 'finishedScore'
  }
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private utilsService: UtilsService,
    public emojiService: EmojiServiceService,
    private mobileAppService: MobileAppService
  ) {}

  getHOF(_id, tourId?){
    return this.http
    .post(environment.baseUrl + 'partners/getHOF', {
      _id,
      tourId,
      groupId: this.route.snapshot.paramMap.get('groupId'),
      sort: this.pageConfig.activeTab
    })
    .subscribe((data: any) => {
      console.log(data);
      if (data.success) {
        this.hofs = data.data;
      }
    });
  }
  setTab(type){
    this.pageConfig.activeTab = type;
    this.getHOF(this.partner._id, this.route.snapshot.paramMap.get('t'));
  }
  getPartnerDetails(_id) {
    return this.http
      .post(environment.baseUrl + 'partners/getPartner', {
        _id,
      })
      .subscribe((data: any) => {
        console.log(data);
        if (data.success) {
          this.partner = data.data;
          this.getHOF(this.partner._id, this.route.snapshot.paramMap.get('t'))

          setInterval(()=>{
            this.getHOF(this.partner._id, this.route.snapshot.paramMap.get('t'))
          },5000)
        } else {
          window.location.href = this.mobileAppService.getURL()+'en/museums';
        }
      });
  }

  refresh(){
    if(this.partner){
      this.getHOF(this.partner._id, this.route.snapshot.paramMap.get('t'))

    }
  }

  ngOnInit() {
    if (this.utilsService.checkDeviceCompatibility()) {
      if(!this.route.snapshot.paramMap.get('p')){
        window.location.href = this.mobileAppService.getURL()+'en/museums';
      }
      this.getPartnerDetails(this.route.snapshot.paramMap.get('p'));
     
    }
  }
}
