<div class="container d-flex align-items justify-content-center align-items-center vh-100">
  <div class="row">
      <div class="col-12 text-center " *ngIf="pageSettings.type === 's' else failedBlock">
          <div>
           <img src="./assets/images/success.png" width="150" alt="">
           <h2>Payment was successfull</h2>
           <h5>You will be redirected to the main page. <br> You will receive the games passes via email shortly.</h5>
           <i class="las la-spinner la-spin la-2x mt-3"></i>
          </div>
        
      </div>
      <ng-template #failedBlock>
        <div class="col-12 text-center ">
          <div>
           <img src="./assets/images/error.png" width="150" alt="">
           <h2>Payment was unsuccessfull</h2>
           <h5>You will be redirected to the main page.</h5>
           <i class="las la-spinner la-spin la-2x mt-3"></i>
          </div>
        
      </div>
      </ng-template>
  </div>
</div>