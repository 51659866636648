import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GameService {
  geoServiceSubject$ = new Subject();
  isPartnerGame = false;
  partner;
  partnerDetails;
  activeGame;
  activeStoredGame;
  pageConfig = {
    g: null,
    p: null
  }
  endQuest = {
      isVisible: false,
      coordinates: []
  }
  mapPlayers = [];
  originalQuests = [];
  battleCircle  = {
    "type": "geojson",
    "data": {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": []
        }
    }
}
  interimSettings = {
    doubleActive: false,
    freezeActive: false,
    mapTapActive: false,
  }
  me: any = {
    _id: null,
    position: null,
    points: 0,
    backpack: [],
    ranking: null,
    name: null,
    isFinished: false,
    passedQuests: [],
    perkEvents: []
  };


  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor;
  
        // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
      }
  
      if (/android/i.test(userAgent)) {
          return "Android";
      }
  
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
      }
  
      return "unknown";
  }

  validateDevice(){
    // Check for location access, check for internet access
    // If problem -> Redirect to error page
  }

  updateMe(){

  }
  constructor() { }


}
