import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ApplicationRef, ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { LocationService } from '../services/location.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as mapboxgl from 'mapbox-gl';

import Noty from 'noty';
import { EmojiServiceService } from '../services/emoji-service.service';
import { GeoService } from '../services/geo.service';
import { GameService } from '../services/game.service';
import * as turf from '@turf/turf';
declare let L;
import Swal from "sweetalert2";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WalkthroughModalComponent } from '../components/walkthroughModal/walkthroughModal.component';
import { UtilsService } from '../services/utils.service';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { LocaleService } from '../services/locale.service';
import { StorageService } from '../services/storage.service';
import * as PubNub from 'PubNub';
import { Subject } from 'rxjs';
import { LocationStrategy } from '@angular/common';
import { MobileAppService } from '../services/mobile-app.service';



@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss'],
})
export class GameComponent implements OnInit, AfterViewInit {

  @ViewChild('scanner', { static: false }) scanner: ZXingScannerComponent;
  @ViewChild('camCorderModal', { static: false }) camCorderModal;
  hasDevices: boolean;
  hasPermission: boolean;
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo;
  pubnub;
  dynamic_url;
  bgColor1;
  playerRanking = 0;
  notPositioned = false;
  gameCountdown;
  gameStartCount;
  inactivityCountdown;
  playerSynch;
  otherPlayerSynch
  battleCircleCountdown;
  geoLocationWatch;
  mapStyle = 'mapbox://styles/ercseypeter1/ckksgqro810vr17ord4zmvbum';
  swiperConfig = {
    spaceBetween: 10,
    loop: false,
    slidesPerView: 2,
    loopedSlides: true,
    centeredSlides: false
  };

  perks = [{ name: 'freeze', description: 'You can freeze all players for 30 seconds', image: './assets/images/emoji/ice.png', teamPerk: true, date: new Date(), point: 100, defaultPoint: 100, used: null }, { name: 'double', image: './assets/images/emoji/slot.png', description: 'Double your points at the next question', point: 100, date: new Date(), defaultPoint: 100, used: null }];
  activeLayers = [];
  messageHistory = [];
  previewImage;
  activeLevel = 1;
  pageConfig = {
    showQrReader: false,
    playerId: null,
    remainingMinutes: 0,
    center: [-74.2605542, 40.6971478],
    zoom: [12],
    lastPositionRefresh: moment(),
    remainingSentSlots: null,
    initialQuestion: false,
    showcaseIsActive: false
  };
  loaders = {
    getPosition: false,
  };
  museumPlayers = [];
  activeBounds = [];
  availableGifs = [];
  activePerks = {
    bomb: false,
    blank_map: false
  };
  createdBombPerkBy;
  map;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private locationService: LocationService,
    public emojiService: EmojiServiceService,
    private appRef: ApplicationRef,
    private geoService: GeoService,
    public gameService: GameService,
    private ngZone: NgZone,
    private modalService: NgbModal,
    private utilsService: UtilsService,
    private changeDetectorRef: ChangeDetectorRef,
    public localeService: LocaleService,
    private storageService: StorageService,
    private cd: ChangeDetectorRef,
    private location: LocationStrategy,
    private mobileAppService: MobileAppService
  ) {

    this.perks =  [{ name: 'freeze', description: this.localeService.getTrans('You can freeze all players for 30 seconds'), image: './assets/images/emoji/ice.png', teamPerk: true, date: new Date(), point: 100, defaultPoint: 100, used: null }, { name: 'double', image: './assets/images/emoji/slot.png', description: this.localeService.getTrans('Double your points at the next question'), point: 100, date: new Date(), defaultPoint: 100, used: null }];

   /*  history.pushState(null, null, window.location.href);  
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });   */

    this.gameService.geoServiceSubject$.subscribe((val: any) => {
      if(val && val.bomb){
          this.activePerks.bomb = false;
          this.http.post(environment.baseUrl + 'games/markBombPassed', { gameId: this.gameService.pageConfig.g, playerId: this.route.snapshot.paramMap.get('p'), createdBombPerkBy: this.createdBombPerkBy }).subscribe((data: any) => {
            this.createdBombPerkBy = null;
            this.changeDetectorRef.detectChanges();
          });
      }
    });

    if (this.route.snapshot.paramMap.get('g') && this.route.snapshot.paramMap.get('p')) {
      this.pageConfig.playerId = this.route.snapshot.paramMap.get('p');
      if (this.route.snapshot.paramMap.get('inGame')) {
        this.pageConfig.initialQuestion = true;
      }
      this.gameService.pageConfig.g = this.route.snapshot.paramMap.get('g');
      this.gameService.pageConfig.p = this.route.snapshot.paramMap.get('p');

      if(this.locationService.isValidBrowser()){
        this.getGame(this.route.snapshot.paramMap.get('g'), this.route.snapshot.paramMap.get('p'));
      }
     
      
    } else {
      this.navigateBack();
    }

  }

  startCountdown(){
    const eventTime= moment('2024-10-03 17:00:00.123+01:00').unix()
    const currentTime = moment(Date.now()).unix();
    let diffTime = eventTime - currentTime;
    let duration: any = moment.duration(diffTime*1000, 'milliseconds');
    const interval = 1000;
    this.gameStartCount=  setInterval(()=>{
      duration = moment.duration(duration - interval, 'milliseconds');
      if(duration.seconds() <= 0 && duration.minutes() <= 0 && duration.hours() <= 0 && duration.days() <= 0){
        console.log('END GAME TIME')
        this.doEndGame('time');
      } 
      },1000);
  }


 

  getGameQuests() {
    if (this.gameService.activeGame.tourId.isQR) {
      const markers = _.filter(this.gameService.activeGame.quests, (q) => {
        return (
          q.level === this.activeLevel && !q.isEndPOITimeline && q.isVisible
        );
      });
      return markers
     
    } else {
      return _.filter(this.gameService.activeGame.quests, (q) => {
        return !q.isEndPOITimeline;
      })
    }

  }

  getCircleRadius() {
    let q = this.gameService.activeGame.quests.length;
    if (q < 10) {
      return 20;
    } else if (q >= 10 && q < 15) {
      return 20;
    } else if (q >= 15 && q < 20) {
      return 20;
    } else if (q >= 20 && q < 25) {
      return 25;
    } else if (q >= 25 && q < 30) {
      return 35;
    } else {
      return 70;
    }
  }

  getEndPoi() {
    console.log('originalQuests', this.gameService.originalQuests)
    return _.find(this.gameService.originalQuests, (x) => {
      return x.isEndPoi === true;
    })
  }

  drawCircle(isInit?) {


    if (this.gameService.activeGame.quests && this.gameService.activeGame.quests.length) {
    
      if (this.map) {
        if (this.map.getLayer('state-borders')) {
          this.map.removeLayer('state-borders')
          this.map.removeSource('state-borders')
        }
      }
      console.log("HELLo", this.gameService.activeGame)

      if (this.gameService.activeGame.quests) {
      
        let center;
        let radius;
        let circle;
        console.log("HELLo1", this.gameService.activeGame)

        if (this.gameService.activeGame && !this.gameService.activeGame.isEndPOI) {
         
          center = this.getFeatureCenter();
          radius = (this.pageConfig.remainingMinutes / this.getCircleRadius());
         
          if (isInit) {
            
            this.pageConfig.center = [center.geometry.coordinates[0], center.geometry.coordinates[1]];
          }
          circle = turf.circle(center.geometry.coordinates, radius, { steps: 20, units: 'kilometers' });
        } else {
          console.log("beforeEndpoi")
          let endPoi = this.getEndPoi();
          console.log("endPOI", endPoi)

          center = [endPoi.lng, endPoi.lat];
          radius = (this.pageConfig.remainingMinutes / this.getCircleRadius());
          circle = turf.circle(center, radius, { steps: 20, units: 'kilometers' });
        }

       
        this.gameService.battleCircle.data.geometry.coordinates = [_.map(circle.geometry.coordinates[0], (x) => {
          return [x[0], x[1]]
        })];
      }
    }

  }


  getFeatureCenter() {
    let features = [];
    let featuresTurf;
    _.forEach(this.gameService.activeGame.quests, (q) => {
      features.push([q.lng, q.lat]);
    })
    featuresTurf = turf.points(features);
    let center = turf.center(featuresTurf);
    return center;
  }


  dropPIN() {
    this.dropNoty('info', this.localeService.getTrans('PIN to join the game: ') + this.gameService.activeGame.pin)
  }

  dropWalkthrough() {
    //this.router.navigate(['/walkthrough']);
    //const modalRef = this.modalService.open(WalkthroughModalComponent);
    //modalRef.componentInstance.mode = 'embed'
    this.clearIntervals();
    if (this.gameService.activeGame.partner && this.gameService.activeGame.partner.isMuseum) {
      this.router.navigate(['/museum-walkthrough', { g: this.gameService.activeGame._id, p: this.gameService.me.name, partner: this.gameService.activeGame.partner._id }])
      this.gameService.activeGame = null;

    } else {
      this.router.navigate(['/walkthrough', { g: this.gameService.activeGame._id, p: this.gameService.me.name, m: 'embed' }])

    }

  }

  locateMe() {
    if (this.gameService.me && this.gameService.me.position) {
      this.map.flyTo({ center: [this.gameService.me.position.lng, this.gameService.me.position.lat], duration: 0 });
      this.pageConfig.zoom = (this.pageConfig.zoom[0] > 18) ? this.pageConfig.zoom : [18];
    }
  }

  refresh(){
    window.location.reload();
  }

  mapClicked(event) {
    
    if(this.gameService.interimSettings.mapTapActive || this.pageConfig.showcaseIsActive){
      if (event.lngLat) {
        this.geoService.isQuestionNearby(this.gameService.activeGame.quests, {
         lat: event.lngLat.lat,
         lng: event.lngLat.lng,
       }, this.activePerks);
       this.gameService.me.position = {
         lat: event.lngLat.lat,
         lng: event.lngLat.lng,
       };
 
      }    
    }
   
  }

  mins() {
    //this.pageConfig.remainingMinutes--;
    this.gameService.activeGame.tourId.length--;
  }
  scrollToContainer(id, player) {
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth'
    });
    if (player.position && player.position.lng && player.position.lat) {
      this.pageConfig.center = [player.position.lng, player.position.lat];
      this.pageConfig.zoom = [18];
    }

    setTimeout((x) => {
      this.locateMe();
    }, 5000)
  }

  getLocationConstantly() {

   

    const options = {maximumAge: 3000, timeout: 5000, enableHighAccuracy: true};

      if(this.geoLocationWatch){
        navigator.geolocation.clearWatch(this.geoLocationWatch);
      }
    this.geoLocationWatch = navigator.geolocation.watchPosition((object) => {
      
      if (!this.notPositioned && this.map) {
        this.notPositioned = true;
        this.map.flyTo({ center: [object.coords.longitude, object.coords.latitude], duration: 0 });
      }
      if(!this.gameService.interimSettings.mapTapActive && !this.pageConfig.showcaseIsActive){

        this.loaders.getPosition = true;
        this.gameService.me.position = {
          lat: object.coords.latitude,
          lng: object.coords.longitude
        };
  
        if (this.geoService.canLookup) {
          this.geoService.isQuestionNearby(this.gameService.activeGame.quests, {
            lat: object.coords.latitude,
            lng: object.coords.longitude
          }, this.activePerks);
          this.ping();
        }
  
  
        //this.geoService.updateUser();
  
        this.loaders.getPosition = false;
      }


    }, (error) => {
      this.loaders.getPosition = false;
      if (error && error.code === 1) {
        this.locationService.dropError();
      } else if(error && error.code === 3){
        this.dropNoty('error', this.localeService.getTrans('Getting location data takes more time than usual. Please wait for the map to initialize'), null, 5000);
        this.getLocationConstantly();
      }
    }, options);
  }

  getLocation() {
    this.pageConfig.lastPositionRefresh = moment();
    this.loaders.getPosition = true;
    this.locationService.getPosition().then((x: any) => {


      this.ngZone.run(() => {
        this.dropNoty('success', this.localeService.getTrans("Position updated!"))

        this.gameService.me.position = {
          lat: x.coords.latitude,
          lng: x.coords.longitude
        };
        this.geoService.isQuestionNearby(this.gameService.activeGame.quests, {
          lat: x.coords.latitude,
          lng: x.coords.longitude
        }, this.activePerks);
        this.pageConfig.center = [x.coords.longitude, x.coords.latitude];

        if (this.map.getZoom() < this.pageConfig.zoom) {
          this.pageConfig.zoom = [15];
        }

        this.geoService.updateUser();
        this.loaders.getPosition = false;
      });
    }, (err) => {

      this.ngZone.run(() => {
        this.loaders.getPosition = false;
        this.locationService.dropError();
      });
    });
  }

  colorize() {
    if (this.gameService.activeGame && this.gameService.activeGame.partner.color) {
      let elems = document.querySelectorAll('.fi-map-control');
      Array.prototype.forEach.call(elems, (el) => {
        el.style.backgroundColor = this.bgColor1;
        el.style.borderColor = this.bgColor1;
      });
      elems = document.querySelectorAll('.flinkint-btn.primary');
      Array.prototype.forEach.call(elems, (el) => {
        el.style.backgroundColor =  this.bgColor1;
        el.style.borderColor =  this.bgColor1;
      });
      elems = document.querySelectorAll('.fi-game-card');
      Array.prototype.forEach.call(elems, (el) => {
        el.style.backgroundColor =  this.bgColor1;
      });


    }
  }
  initGame() {
    setTimeout(() => {
      if (this.gameService.activeGame.tourId.isBattleCircle) {
        this.drawCircle(true)
      }
    }, 3000)
  }

  refreshCircle() {
    // Todo calculate
    //3/90
  }

  terminateGameDueInactivity() {
    this.inactivityCountdown = setInterval(() => {
      const endTime = moment.duration(moment().diff(this.gameService.activeGame.startedDate));
      console.log(endTime)
      if (endTime.asHours() > 48) {
        window.location.href = "https://jobverse-admin.azurewebsites.net/jobverse";
      }
    }, 5000);
  }

  startGameCountdown() {
    this.startCountdown();
    this.gameCountdown = setInterval(() => {
      this.compareTimeDifference();
    }, 1000);
    if (this.gameService.activeGame && this.gameService.activeGame.tourId.isStory && this.gameService.activeGame.tourId.isBattleCircle) {
      this.battleCircleCountdown = setInterval(() => {
        this.drawCircle();
      }, 5000);
    }

  }

  clearIntervals() {
    const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
  }

  compareTimeDifference() {
    const endTime = moment(this.gameService.activeGame.startedDate).add(this.gameService.activeGame.tourId.length, 'minutes');

    if (!this.pageConfig.initialQuestion) {
      let qu = {
        "question": this.localeService.getTrans("What is the goal of the game?"),
        "type": "0",
        "answers": [{
          "_id": {
            "$oid": "ab1"
          },
          "isTrue": true,
          "answer": this.localeService.getTrans("To collect all the story points")
        }, {
          "_id": {
            "$oid": "ab2"
          },
          "isTrue": false,
          "answer": this.localeService.getTrans("To wait until the time is up")
        }, {
          "_id": {
            "$oid": "ab3"
          },
          "isTrue": false,
          "answer": this.localeService.getTrans("To stay outside the battle circle")
        }],
        "time": 60,
        "mediaUrl": null,
        "_id": "qmb123",
        "points": 200
      }
      console.log('TXT MESSAGE')
      if (this.gameService.me.passedQuests.indexOf(qu._id) === -1) {
        this.pageConfig.initialQuestion = true;
        this.geoService.questionDetected(qu, null, this.activePerks);
      } else {
        this.pageConfig.initialQuestion = true;
      }

    }
    this.pageConfig.remainingMinutes = Math.floor(moment.duration(endTime.diff(moment())).asMinutes());
    /* if((Math.floor(moment.duration(this.pageConfig.lastPositionRefresh.diff(moment())).asSeconds())) < -60){
      this.pageConfig.lastPositionRefresh = moment();
      const element = document.querySelector('.animatebutton');
      element.classList.add('animated', 'swing');
      setTimeout(function() {
        element.classList.remove('swing');
      }, 1000);
      this.dropNoty('success', this.localeService.getTrans("Don't forget to use the 'Refresh my position' button regularly to refresh your current location in the game."))
    } */

    if ((this.pageConfig.remainingMinutes === 5 || this.pageConfig.remainingMinutes === 10 || this.pageConfig.remainingMinutes === 15) && this.pageConfig.remainingSentSlots !== this.pageConfig.remainingMinutes) {
      this.dropNoty('info', this.localeService.getTrans("Hurry up, only ") + this.pageConfig.remainingMinutes + this.localeService.getTrans(' minutes left!'));
      this.pageConfig.remainingSentSlots = this.pageConfig.remainingMinutes;
    }

    if (this.pageConfig.remainingMinutes <= 0) {
      this.clearIntervals();
      navigator.geolocation.clearWatch(this.geoLocationWatch);
      this.geoService.endGame('timeout')
  
    }
  }
  dropNoty(type, text, clickEvent?, timeout?) {
    new Noty({
      type: type,
      text: text,
      killer: true,
      timeout,
      callbacks: {
        onClick: () => {
          if (clickEvent && clickEvent === 'safehouse') {
            this.pageConfig.center = [this.gameService.endQuest.coordinates[0], this.gameService.endQuest.coordinates[1]]
            this.pageConfig.zoom = [22];
          }
        }
      }
    }).show();
  }
  dropTooltip(image, title, text) {
    Swal.fire({
      imageUrl: image,
      title,
      text,
      showDenyButton: false,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: this.localeService.getTrans(`Got it`),
      confirmButtonColor: this.bgColor1,
      cancelButtonText: 'Back'
    })
  }
  clickedBackpack(item) {

    Swal.fire({
      imageUrl: item,
      title: this.localeService.getTrans('Backpack item'),
      text: this.localeService.getTrans('This item may help you to solve the final riddle.'),
      showDenyButton: false,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: this.localeService.getTrans(`Got it`),
      confirmButtonColor: this.bgColor1,
      cancelButtonText: this.localeService.getTrans('Back')
    })

    //this.dropNoty('info','This item will be useful and the end of the game.')
  }
  refreshPosition() { }

  showPlayerInfo(player) {
    console.log(this.emojiService.getEmojiByIndex(player.avatarIndex).toString())

    Swal.fire({
      imageUrl: this.emojiService.getEmojiByIndex(player.avatarIndex).icon.toString(),
      title: player.name,
      confirmButtonColor: this.gameService.activeGame.partner ? this.gameService.activeGame.partner.color : null,
      text: player.name + ' egy további játékos a kiállításon',
      confirmButtonText: this.localeService.getTrans(`Got it`)
    });
  }

  checkMapTapPerk(users){
    console.log('users',users)
    const otherPlayers = _.filter(users, (user) => { return user.name === this.pageConfig.playerId });
    const perkEvents = _.orderBy(_.flatten(_.map(otherPlayers, (player) => { return _.filter(player.perkEvents, (perkEvent)=>{return perkEvent.type === 'map_tap'}) })), ['created'], ['desc']);

    console.log(perkEvents, 'perkEvents');
    if(perkEvents.length > 0){
      let tm = moment.unix(perkEvents[0].created);
    
      if(Math.floor(moment.duration(tm.diff(moment())).asSeconds())>-35){
      console.log('ACTIVE MAP ')
        this.gameService.interimSettings.mapTapActive = true;
      } else{
        console.log('NOT ACTIVE MAP ')
        this.gameService.interimSettings.mapTapActive = false;
      }
    }
  }

  checkFreezePerk(users){
   
    const otherPlayers = _.filter(users, (user) => { return !user.isMe });
    const perkEvents = _.orderBy(_.flatten(_.map(otherPlayers, (player) => { return _.filter(player.perkEvents, (perkEvent)=>{return perkEvent.type === 'freeze'}) })), ['created'], ['desc']);

    if(perkEvents.length > 0){
      let tm = moment.unix(perkEvents[0].created);
    
      if(Math.floor(moment.duration(tm.diff(moment())).asSeconds())>-35){
        this.gameService.interimSettings.freezeActive = true;
      } else{
        this.gameService.interimSettings.freezeActive = false;
      }
    }
   
  }

  checkBombPerk(users){
    const bombEvent = _.orderBy(_.flatten(_.map(users, (player) => { return _.filter(player.perkEvents, (perkEvent)=>{return perkEvent.type === 'bomb' &&  perkEvent.target === this.gameService.me.name && perkEvent.isActive === true }) })), ['created'], ['desc']);
    console.log(bombEvent)
    if(bombEvent.length){
      this.activePerks.bomb = true
      this.createdBombPerkBy = bombEvent[0].createdBy;
    }
  }

  checkBlankMapPerk(users){
   
    const otherPlayers = _.filter(users, (user) => { return !user.isMe });
    const perkEvents = _.orderBy(_.flatten(_.map(otherPlayers, (player) => { return _.filter(player.perkEvents, (perkEvent)=>{return perkEvent.type === 'blank_map'}) })), ['created'], ['desc']);

    if(perkEvents.length > 0){
      let tm = moment.unix(perkEvents[0].created);
    
      if(Math.floor(moment.duration(tm.diff(moment())).asSeconds())>-35){
        this.mapStyle = 'mapbox://styles/ercseypeter1/cl7hh0afu002m14qg80cjrff1';
        this.activePerks.blank_map = true
      } else{
        this.mapStyle = this.gameService.activeGame.tourId.style.mapStyle;
        this.activePerks.blank_map = false;
      }
    }
   
  }

  countPerks(type){
    let perkEvents =  _.filter(this.gameService.me?.perkEvents , (perkEvent)=>{return perkEvent.type === type});
    return perkEvents.length > 0 ? perkEvents.length+1 : 1;
  }

  synchOtherPlayers() {
    return this.http.post(environment.baseUrl + 'games/getOtherPlayers', { groupId: this.gameService.activeGame.groupId, playerId: this.gameService.me._id , isDigitalMuseum: this.gameService.activeGame.partner ? this.gameService.activeGame.partner.isDigitalMuseum  : null,   partnerId: this.gameService.activeGame.partner ? this.gameService.activeGame.partner._id : null, tourId: this.gameService.activeGame.tourId._id }).subscribe((data: any) => {
      _.remove(data.data, (d)=>{return !d})
      this.museumPlayers = _.orderBy(data.data, ['points'], ['desc']);

      if (data.success) {
        _.remove(data.data, (x) => {
          return !x;
        })
        _.remove(data.data, (x) => {
          return x.name === this.pageConfig.playerId;
        })

        this.gameService.mapPlayers = _.cloneDeep(_.filter(data.data, (d)=>{return !d.isFinished}))
        this.gameService.me.ranking = _.findIndex(_.orderBy(this.gameService.mapPlayers, ['points'], ['desc']), (r)=>{
          return (r.name === this.pageConfig.playerId);
        })

        this.checkFreezePerk(data.data);
        this.checkMapTapPerk(this.museumPlayers);
        this.checkBlankMapPerk(data.data);
        this.checkBombPerk(data.data);
      }

    });
  }

  initPubnub() {

    let id = _.find(this.gameService.activeGame.players,(p)=>{return p.name === this.route.snapshot.paramMap.get('p')}) 
    this.gameService.me = id;
 
    this.pubnub = new PubNub({
      subscribeKey: "sub-c-e07e43ee-5694-11eb-bf6e-f20b4949e6d2",
      publishKey: "pub-c-125b7199-434c-4139-8b37-3a36487c543d",
      uuid: id._id
    });
   
   // this.subscribeForPubnub(); 
  }

  getPubnubHistory(){
   /* this.pubnub.fetchMessages(
      {
          channels: [this.gameService.activeGame.groupId],
          count: 100
      },
      (status, response) => {
        if(response.channels && response.channels && response.channels[this.gameService.activeGame.groupId]){
         
          this.messageHistory = _.map(response.channels[this.gameService.activeGame.groupId], (f)=>{
            return {...f, ...{time: moment.unix(f.timetoken/10000000).fromNow()}}
          });
          this.reorderMessageHistory();
        }
        
      }
  );
    */
  }

  subscribeForPubnub() {
    this.pubnub.addListener({
      message: (msg) => {
        this.updatePlayerAll(msg)
      }
    })
    this.pubnub.subscribe({
      channels: [this.gameService.activeGame.groupId]
    });
    this.getPubnubHistory();
    
    //this.ping();
  }


  reorderMessageHistory(){
    this.messageHistory = _.orderBy(this.messageHistory, ['timetoken'], ['desc']);
  }

  touchMap(){
      this.geoService.mapTouched = true;
  }

  updatePlayerAll(msg) {

    console.log(msg)
    if(msg.message.adminMessage){
      this.dropNoty('info', 'Admin: ' + msg.message.adminMessage);
      this.messageHistory.push({...msg, ...{time: moment.unix(msg.timetoken/10000000).fromNow()}});
      this.reorderMessageHistory();
      this.geoService.vibrate();
    }

  /*   if(msg.message.player !== ('admin-' + this.gameService.activeGame._id)){
      this.gameService.activeGame.players.splice(_.findIndex(this.gameService.activeGame.players, {_id: msg.message.player}), 1, msg.message.payload);
      this.gameService.mapPlayers = _.filter(_.cloneDeep(this.gameService.activeGame.players), (y)=>{
        return y._id !== this.gameService.me._id;
      });
  
      if(msg.message.perks && msg.message.perks.length){      
        if(msg.message.perks[0] && msg.message.perks[0].created && msg.message.perks[0].creator !== this.route.snapshot.paramMap.get('p')){
          let tm = moment(msg.message.perks[0].created);
          if(msg.message.perks[0].name === 'freeze' && Math.floor(moment.duration(tm.diff(moment())).asSeconds())>-30){
            this.gameService.interimSettings.freezeActive = true;
          } else{
            this.gameService.interimSettings.freezeActive = false;
          }
        }
      }
  
      if(msg.message.type){
        if(msg.message.type === 1){
          this.geoService.endGame('direct')
          //this.endGame('direct');
        }
  
      }
  
      this.geoService.calculateRanking();
      this.cd.markForCheck();
    } else{
      if(msg.message.adminMessage){
        this.dropNoty('info', 'Admin: ' + msg.message.adminMessage);
      }
    } */
    

  }
  

  getRankingMapPlayers(){
    return _.orderBy(this.museumPlayers, [(o)=> { return o.points; }], ['desc']);
  }
  getRanking(){
    return _.orderBy(this.gameService.activeGame.players, [(o)=> { return o.points; }], ['desc']);
  }

  ping(type?) {
    
    let publishPayload = {
      channel: this.gameService.activeGame._id,
      message: {
        player: this.gameService.me._id,
        position: this.gameService.me.position,
        points: this.gameService.me.points,
        payload: this.gameService.me,
        perks: this.gameService.activeGame.teamPerks,
        type: type
      }
    }
    if (this.pubnub) {
      this.pubnub.publish(publishPayload);
    }
  }

  synchPlayers() {
      this.initPubnub();
      if(!this.gameService.activeGame.tourId.isQR){
        this.getLocationConstantly();
      }

    /*  return this.http.post(environment.baseUrl + 'games/getPlayers', {gameId: this.gameService.activeGame._id} ).subscribe((data: any) => {
      
       if(data.success){
         this.gameService.activeGame.players = data.data.players;
         this.gameService.mapPlayers = _.cloneDeep(data.data.players);
 
         
         let me = _.find(this.gameService.activeGame.players, (x) => {
             return (x.name === this.pageConfig.playerId);
         });
       
       
         this.perks = _.map(this.perks, (p)=>{
          
           return _.merge(p, {
             point: p.defaultPoint+(me.perkCounter*100)
           })
           
         })
 
         this.gameService.activeGame.quests = _.filter(this.gameService.activeGame.quests, (q)=>{
           return me.passedQuests.indexOf(q._id) === -1
         }) 
 
         // TODO (calculate that all players collected team points)
         // this.isTeamQuestSuccessful();
        
         _.remove(this.gameService.mapPlayers, (x) => {
           return (x.name === this.pageConfig.playerId);
         });
 
       
         // Igazából innentől kéne beépíteni
 
         if(!_.find(this.gameService.activeGame.quests, (q)=>{
           return q.questionType === 'story'
         })){
           if(!this.gameService.isPartnerGame){
             this.activateGameEndPOI();
           } else{
             if(_.filter(this.gameService.activeGame.quests, (quest)=>{return quest.isQuestion}).length === 0){
               this.endGame('end');
             }
           }
          f
         }
 
         if(_.filter(this.gameService.activeGame.quests, (quest)=>{return quest.isQuestion}).length === 0){
           this.endGame('end');
         }
 
          if((this.gameService.me.passedQuests.length <= me.passedQuests.length)){
           this.gameService.me = me;
         } 
        
         this.museumPlayers = _.orderBy(_.merge(this.museumPlayers, this.gameService.me), ['points'],['desc']);
        
         if(data.data.teamPerks && data.data.teamPerks.length){
         
           if(data.data.teamPerks[0] && data.data.teamPerks[0].created && data.data.teamPerks[0].creator !== this.route.snapshot.paramMap.get('p')){
             let tm = moment(data.data.teamPerks[0].created);
           
             if(data.data.teamPerks[0].name === 'freeze' && Math.floor(moment.duration(tm.diff(moment())).asSeconds())>-30){
               this.gameService.interimSettings.freezeActive = true;
             } else{
               this.gameService.interimSettings.freezeActive = false;
             }
           }
         
         }
 
         if(me.isFinished || this.gameService.activeGame.isFinished){
           this.endGame('endPoi');
         }
         this.gameService.me.ranking = _.findIndex(_.orderBy(this.gameService.activeGame.players, ['points'], ['desc']), (r)=>{
           return (r.name === this.pageConfig.playerId);
         })
 
         if(isInit){
          
 
           if(this.gameService.activeGame.indoorMode){
             this.initIndoorMode(this.gameService.activeGame.quests[0]);
           } else if(!this.gameService.activeGame.tourId.isQR){
 
             this.getLocationConstantly();
             //this.getLocation();
 
           }
              
         }
       } 
     });  */
  }
  isTeamQuestSuccessful() {

  }

  uploadImage(){
    this.modalService.dismissAll();
     let myFile: any = new FormData();
    myFile.append("myFile", this.previewImage); 
    return this.http.post(environment.baseUrl + 'games/uploadImage', myFile).subscribe((data: any) => {
      this.updateUserWithImageAndLocation(data.url);
      this.dropNoty('success', this.localeService.getTrans('Image successfully uploaded'));
     
    });
  }

  uploadImages(event){
    console.log(event)
    const files = (event.target as HTMLInputElement).files;
    console.log(files[0])
    this.previewImage = files[0];


    const reader = new FileReader();
    reader.readAsDataURL(this.previewImage);
    reader.onload = () => {
      const image: any = new Image();
      image.src = reader.result;
      this.dynamic_url = image.src;
     /*  image.onload = () => {
        const canvas: any = document.querySelector('#previewContainer canvas');
        canvas.classList.add('img-fluid');
        var heightRatio = 1.5;
        canvas.height = canvas.width * heightRatio;
        const context = canvas.getContext('2d');
        context.drawImage(image, 0, 0);
      } */
    }
  }

  updateUserWithImageAndLocation(fileUrl){
    return this.http.post(environment.baseUrl + 'games/updateUserWithImageAndLocation', {fileUrl, user: this.gameService?.me }).subscribe((data: any) => {
      this.dropNoty('info', this.localeService.getTrans("Image successfully uploaded"));
    });
  }

  dropCamcorder(){
    this.modalService.open(this.camCorderModal, {centered: true});
  }

  togglePerk(content, perk) {

    if (this.gameService.me.points < perk.points) {
 
      this.dropNoty('info', this.localeService.getTrans("You don't have enough points to use this perk."));

    } else {
      let selectedPerk = perk;

      Swal.fire({
        imageUrl: './assets/images/v3/photo.svg',

        title: this.getPerkModalLocale(selectedPerk.type).title,
        text: this.getPerkModalLocale(selectedPerk.type).text,
        showDenyButton: false,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: this.localeService.getTrans(`Use perk`),
        confirmButtonColor: this.bgColor1,
        cancelButtonText: this.localeService.getTrans('Back')
      }).then((result) => {
        if (result.isConfirmed) {
          if(selectedPerk.type === 'stand' || selectedPerk.type === 'jobverse' || selectedPerk.type === 'astronaut'){
            this.previewImage = null;
            this.dynamic_url = null;
            this.dropCamcorder();
          } else{
            if (selectedPerk.type === 'freeze') {
              this.dropNoty('info', this.getPerkModalLocale(selectedPerk.type).info);
            } else if(selectedPerk.type === 'bomb'){
              let players =  _.filter(this.museumPlayers, (player)=>{return player.name !== this.gameService.me.name});
              if(players.length){
                selectedPerk.target = players[Math.floor(Math.random()*players.length)].name;
                this.dropNoty('info', this.getPerkModalLocale(selectedPerk.type).info);
              } else{
                this.dropNoty('info', this.localeService.getTrans('No other players to use this perk on.'));
                return false;
              }
            
            } else if(selectedPerk.type === 'map_tap'){
              this.dropNoty('info', this.getPerkModalLocale(selectedPerk.type).info);
            }
            return this.http.post(environment.baseUrl + 'games/usePerk', {u: this.gameService.me._id, perk: selectedPerk, t: moment().unix() }).subscribe((data: any) => {
              this.gameService.me = data;
            });
          }
        
        }
       
      });

    }

  }

  getPerkModalLocale(type) {
    let locales = {
      'freeze': {
        title: this.localeService.getTrans('Freeze'),
        text: this.localeService.getTrans('You can freeze all players for 30 seconds'),
        info: this.localeService.getTrans('Freeze perk is active. You frozed all other players for 30 seconds.')
      },
      'blank_map': {
        title: this.localeService.getTrans('Splashed egg'),
        text: this.localeService.getTrans('A splashed egg is displayed to players, obscuring the map for 30 seconds.'),
        info: this.localeService.getTrans('Splashed egg perk is active for 30 seconds.')
      },
      'photo': {
        title: this.localeService.getTrans('Photo gallery'),
        text: this.localeService.getTrans('You can take photos during the game.'),
        info: this.localeService.getTrans('You just activated the photo perk.')
      },
      'bomb': {
        title: this.localeService.getTrans('Bomb'),
        text: this.localeService.getTrans('You can throw a bomb on a random player.'),
        info: this.localeService.getTrans('You just activated the bomb perk.')
      }, 'map_tap': {
        title: this.localeService.getTrans('Map tap'),
        text: this.localeService.getTrans('Simulate your position by tapping on the map.'),
        info: this.localeService.getTrans('It allows you to simulate their position by tapping on the map, which allows you to reach remote points for up to 30 seconds.')
      },
      'stand': {
        title: 'Kedvenc stand',
        text: 'Készíts fotót a kedvenc standodról!',
        info: this.localeService.getTrans('You just activated the photo perk.')
      },
      'jobverse': {
        title: 'JOBVERSE élmény',
        text: 'Készíts fotót az  igazi JOBVERSE élményről és töltsd fel. Legyen az egy ChillZoneban pihenés, vagy Karrerszínpad egy előadása, a kedvenc ajándékod, amit egy kiállítótól kaptál.',
        info: this.localeService.getTrans('You just activated the photo perk.')
      },
      'astronaut': {
        title: 'Asztronauta',
        text: 'Tegyél fel egy közös képet az Asztronautával, és már jár is a bónusz pont!',
        info: this.localeService.getTrans('You just activated the photo perk.')
      }    

      
    }

    return locales[type];

  }

  getPlayers() {
   

   
    this.geoService.getUserByTeamName(this.route.snapshot.paramMap.get('p'));
  /*   this.playerSynch = setInterval(() => {
      this.synchPlayers();
    }, 8000); */
      this.synchOtherPlayers();
      this.otherPlayerSynch = setInterval(() => {
        this.synchOtherPlayers();
        this.geoService.updateUserIfOK();
      }, 10000);
  
   
    setTimeout(() => {
      this.synchPlayers();
    }, 3000)
    
  /*   if(this.route.snapshot.paramMap.get('p') === 'admin-terminator'){
      this.otherPlayerSynch = setInterval(() => {
          this.geoService.updateUserStress();
          //this.ping();
      }, 1000);
    } */

  }
  removeMapComponent() {
    if (this.map) {
      this.map.destroy();
    }
  }
  endGameDirectly() {
    Swal.fire({
      title: this.localeService.getTrans('Do you really want to end the game?'),
      text: this.localeService.getTrans("You cannot rejoin in the game if you hit 'End Game'"),
      showDenyButton: false,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: this.localeService.getTrans(`End Game`),
      confirmButtonColor: this.bgColor1,
      cancelButtonText: this.localeService.getTrans('Back')
    }).then((result) => {
      if (result.isConfirmed) {
        if(this.geoLocationWatch){
          navigator.geolocation.clearWatch(this.geoLocationWatch);
        }
        this.geoService.endGame('direct');
        //this.endGame('direct');
        this.ping(1)
      }
    })
  }

  endGame(reason) {
    this.clearIntervals();
    if (this.gameService.activeGame.isEndPOI) {
      this.doEndGame(reason);
    } else {
      this.doEndGame(reason);
    }
  }

  doEndGame(reason) {
    if(this.geoLocationWatch){
      navigator.geolocation.clearWatch(this.geoLocationWatch);
    }
    this.clearIntervals();
    this.geoService.endGame(reason);
  }

  initIndoorMode(question) {
    this.geoService.questionDetected(question, true, this.activePerks);
  }
  toggleLevels() {

    if (this.activeLevel === _.maxBy(this.gameService.activeGame.partner.raster_image_url, (x) => { return x.level }).level) {
      this.activeLevel = 1;
    } else {
      this.activeLevel = this.activeLevel + 1;
    }
    this.setLevels(this.activeLevel)

  }
  setLevels(level) {
    this.activeLayers = _.filter(this.gameService.activeGame.partner.raster_image_url, (x) => {
      return x.level === level;
    })
  }

  scanQR() {
    this.pageConfig.showQrReader = true;
    this.changeDetectorRef.detectChanges();
    this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
      this.hasDevices = true;
      this.availableDevices = devices;

      // selects the devices's back camera by default
      for (const device of devices) {
        if (/back|rear|environment/gi.test(device.label)) {
          this.scanner.isCurrentDevice(device);
          this.currentDevice = device;
          break;
        }
      }
    });

    this.scanner.camerasNotFound.subscribe(() => this.hasDevices = false);
    this.scanner.permissionResponse.subscribe((perm: boolean) => this.hasPermission = perm);

  }

  handleQrCodeResult(resultString: string) {
    this.closeQR();
   
    let str = resultString.substr(resultString.indexOf('&qId=') + 5, resultString.length);
    str = str.split('&')[0];
    if (resultString && str) {
      let question = _.find(this.gameService.activeGame.quests, (q) => {
        return q._id == str
      });
      if (question) {
        this.geoService.questionDetected(question, null, this.activePerks);
      } else {
         let question = _.find(this.gameService.activeGame.quests, (q) => {
           return (
             q._id == str
           );
         });
        if(question){
          this.utilsService.dropSwal(
            'Uh oh',
            this.localeService.getTrans('This QR code is not your color'),
            'info'
          );
        } else {
           this.utilsService.dropSwal(
             'Uh oh',
             this.localeService.getTrans(
               'This QR code has been activated already'
             ),
             'info'
           );
        } 
       
      }
    }
  }

  closeQR() {
    this.pageConfig.showQrReader = false;
    if(this.scanner){
      this.scanner.scanStop(); 
    }
  }

  getGame(gameId, player) {
    return this.http.post(environment.baseUrl + 'games/getGame', { gameId, player }).subscribe((data: any) => {

      if (data.success) {
        if(data.data.isFinished){
          window.location.href = "https://jobverse-admin.azurewebsites.net/jobverse";
        } else{
          if (data.data.partner) {
            this.gameService.isPartnerGame = true;
            this.pageConfig.initialQuestion = true;
          }
          this.gameService.activeGame = data.data;
          this.gameService.activeStoredGame = _.cloneDeep(data.data);
          this.mapStyle = this.gameService.activeGame.tourId.style.mapStyle

          this.bgColor1 = this.gameService.activeGame.partner ? ((this.gameService.activeGame.tourId.isCustomBranding && this.gameService.activeGame.tourId.customBranding.color) ? this.gameService.activeGame.tourId.customBranding.color : (this.gameService.activeGame.partner.color ? this.gameService.activeGame.partner.color : null)) : null;

          if(this.route.snapshot.paramMap.get('showcase') === 'true' && this.route.snapshot.paramMap.get('showcase') !== undefined){
            this.pageConfig.showcaseIsActive = true;
          }
          
          this.localeService.locale = this.gameService.activeGame.tourId.locale ? this.gameService.activeGame.tourId.locale : 'en';
          if (this.gameService.activeGame.isMuseum) {
            this.setLevels(1);
            if(this.gameService.activeGame.partner.raster_image_url && this.gameService.activeGame.partner.raster_image_url.length){
              //this.activeBounds = [this.gameService.activeGame.partner.raster_image_url[0].coordinates[0], this.gameService.activeGame.partner.raster_image_url[0].coordinates[2]];
              this.activeBounds = [[this.gameService.activeGame.quests[0].lng, this.gameService.activeGame.quests[0].lat], [this.gameService.activeGame.quests[1].lng, this.gameService.activeGame.quests[1].lat] ];
            }
          }
  
  
  
          this.gameService.originalQuests = data.data.quests;
          //this.gameService.activeGame.quests = data.data.quests;

          
          if (!this.gameService.activeGame.indoorMode) {
            this.getPlayers();
            this.initGame();
          } else {
            this.getPlayers();
          }
  
          setTimeout(() => {
            this.colorize();
          }, 1000);

          setTimeout(() => {
            const qId = this.route.snapshot.paramMap.get('qId')
            if(qId){
              let question = _.find(this.gameService.activeGame.quests, (q) => {
                return q._id == qId
              });
              if (question && this.gameService.me.passedQuests.indexOf(qId) === -1) {
                this.geoService.questionDetected(question, null, this.activePerks);
              } 
            }
        
          }, 3000);
          if (data.data.tourId.isGameTimer) {
            this.startGameCountdown();
          } else {
            this.terminateGameDueInactivity();
          }
        }

   
      } else {
        this.navigateBack()
      }
    });
  }

  logout(){
    window.location.href = 'https://jobverse.flinkit.io/hu/museum-gamepass?uid=64c0dfcd149d77c4b872a836&locale=hu&tid='+this.mobileAppService.gameId+'&type=false&groupId=cce2840c-03c9-44a8-b17f-1f8970dc3f2dm&limit=0&qId';
    //window.location.href = 'http://localhost:4200/museum-gamepass?uid=64c0dfcd149d77c4b872a836&locale=hu&tid='+this.mobileAppService.gameId+'&type=false&groupId=cce2840c-03c9-44a8-b17f-1f8970dc3f2dm&limit=0';

  }



  navigateBack() {
    this.router.navigate(['/']);
  }

  ngOnInit() {
    if (this.utilsService.checkDeviceCompatibility()) {
      this.clearIntervals();
    }

  }

  ngAfterViewInit() {

  }
}




