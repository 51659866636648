import { HttpClient } from '@angular/common/http';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../services/utils.service';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';
import { EmojiServiceService } from '../services/emoji-service.service';
import { SwiperComponent } from 'ngx-useful-swiper';
import { LocaleService } from '../services/locale.service';
import { MobileAppService } from '../services/mobile-app.service';
import { LocationService } from '../services/location.service';
import Noty from 'noty';
@Component({
  selector: 'app-museum-onboarding',
  templateUrl: './museum-onboarding.component.html',
  styleUrls: ['./museum-onboarding.component.scss'],
})
export class MuseumOnboardingComponent implements OnInit {
  @ViewChild('usefulSwiper', { static: false }) usefulSwiper: SwiperComponent;
  coords;
  bgColor;
  isLocation = false;
  pageConfig = {
    loginStep: 0,
    loaders: {
      joinGame: false,
    },
  };
  noty;
  params;
  swiperConfig = {
    spaceBetween: 10,
    loop: false,
    initialSlide: 2,
    slidesPerView: 'auto',
    loopedSlides: 2,
    centeredSlides: true,
  };
  activeGame;
  error;
  emailError;
  playerObj = {
    teamName: null,
    avatarIndex: 0,
    passedQuests: [],
    email: null,
    selectedInstitution: null,
    colorCode: '#A6A6A6',
  };

  institutions = [
    {
      id: 'A Tan Kapuja Buddhista Főiskola (TKBF)',
      name: 'A Tan Kapuja Buddhista Főiskola (TKBF)',
    },
    {
      id: 'Adventista Teológiai Főiskola (ATF)',
      name: 'Adventista Teológiai Főiskola (ATF)',
    },
    {
      id: 'Állatorvostudományi Egyetem (ATE)',
      name: 'Állatorvostudományi Egyetem (ATE)',
    },
    {
      id: 'Ambis College (Vysoká škola regionálního rozvoje a Bankovní institut – AMBIS)',
      name: 'Ambis College (Vysoká škola regionálního rozvoje a Bankovní institut – AMBIS)',
    },
    {
      id: 'Andrássy Gyula Budapesti Német Nyelvű Egyetem (ANNYE)',
      name: 'Andrássy Gyula Budapesti Német Nyelvű Egyetem (ANNYE)',
    },
    {
      id: 'Apor Vilmos Katolikus Főiskola (AVKF)',
      name: 'Apor Vilmos Katolikus Főiskola (AVKF)',
    },
    {
      id: 'Babes Bolyai Tudományegyetem',
      name: 'Babes Bolyai Tudományegyetem',
    },
    {
      id: 'Baptista Teológiai Akadémia (BTA)',
      name: 'Baptista Teológiai Akadémia (BTA)',
    },
    {
      id: 'Bhaktivedanta Hittudományi Főiskola (BHF)',
      name: 'Bhaktivedanta Hittudományi Főiskola (BHF)',
    },
    {
      id: 'Brenner János Hittudományi Főiskola (BJHF)',
      name: 'Brenner János Hittudományi Főiskola (BJHF)',
    },
    {
      id: 'Budapest Kortárstánc Főiskola (BKTF)',
      name: 'Budapest Kortárstánc Főiskola (BKTF)',
    },
    {
      id: 'Budapesti Corvinus Egyetem (BCE)',
      name: 'Budapesti Corvinus Egyetem (BCE)',
    },
    {
      id: 'Budapesti Gazdasági Egyetem (BGE)',
      name: 'Budapesti Gazdasági Egyetem (BGE)',
    },
    {
      id: 'Budapesti Metropolitan Egyetem (METU)',
      name: 'Budapesti Metropolitan Egyetem (METU)',
    },
    {
      id: 'Budapesti Műszaki és Gazdaságtudományi Egyetem (BME)',
      name: 'Budapesti Műszaki és Gazdaságtudományi Egyetem (BME)',
    },
    {
      id: 'CECOS London College (CECOS)',
      name: 'CECOS London College (CECOS)',
    },
    {
      id: 'Central European University, Budapest (CEUNY)',
      name: 'Central European University, Budapest (CEUNY)',
    },
    { id: 'Debreceni Egyetem (DE)', name: 'Debreceni Egyetem (DE)' },
    {
      id: 'Debreceni Református Hittudományi Egyetem (DRHE)',
      name: 'Debreceni Református Hittudományi Egyetem (DRHE)',
    },
    { id: 'Dunaújvárosi Egyetem (DE)', name: 'Dunaújvárosi Egyetem (DE)' },
    {
      id: "École Supérieure des Sciences Commerciales d'Angers (ESSCA)",
      name: "École Supérieure des Sciences Commerciales d'Angers (ESSCA)",
    },
    { id: 'Edutus Egyetem (EDUTUS)', name: 'Edutus Egyetem (EDUTUS)' },
    {
      id: 'Egri Hittudományi Főiskola (EGHF)',
      name: 'Egri Hittudományi Főiskola (EGHF)',
    },
    {
      id: 'Eötvös József Főiskola (EJF)',
      name: 'Eötvös József Főiskola (EJF)',
    },
    {
      id: 'Eötvös Loránd Tudományegyetem (ELTE)',
      name: 'Eötvös Loránd Tudományegyetem (ELTE)',
    },
    {
      id: 'Esztergomi Hittudományi Főiskola (ESZHF)',
      name: 'Esztergomi Hittudományi Főiskola (ESZHF)',
    },
    {
      id: 'Eszterházy Károly Katolikus Egyetem (EKKE)',
      name: 'Eszterházy Károly Katolikus Egyetem (EKKE)',
    },
    {
      id: 'Evangélikus Hittudományi Egyetem (EHE)',
      name: 'Evangélikus Hittudományi Egyetem (EHE)',
    },
    {
      id: 'Fachhochschule Wiener Neustadt (FH)',
      name: 'Fachhochschule Wiener Neustadt (FH)',
    },
    {
      id: 'FernUniversität in Hagen (FU)',
      name: 'FernUniversität in Hagen (FU)',
    },
    { id: 'Gábor Dénes Főiskola (GDF)', name: 'Gábor Dénes Főiskola (GDF)' },
    { id: 'Gál Ferenc Egyetem (GFE)', name: 'Gál Ferenc Egyetem (GFE)' },
    {
      id: 'Golgota Teológiai Főiskola (GTF)',
      name: 'Golgota Teológiai Főiskola (GTF)',
    },
    {
      id: 'IBS Nemzetközi Üzleti Főiskola (IBS)',
      name: 'IBS Nemzetközi Üzleti Főiskola (IBS)',
    },
    {
      id: 'Károli Gáspár Református Egyetem (KRE)',
      name: 'Károli Gáspár Református Egyetem (KRE)',
    },
    {
      id: 'Kodolányi János Egyetem (KJE)',
      name: 'Kodolányi János Egyetem (KJE)',
    },
    { id: 'Közép-európai Egyetem (KEE)', name: 'Közép-európai Egyetem (KEE)' },
    {
      id: 'Liszt Ferenc Zeneművészeti Egyetem (LFZE)',
      name: 'Liszt Ferenc Zeneművészeti Egyetem (LFZE)',
    },
    {
      id: 'Magyar Agrár- és Élettudományi Egyetem',
      name: 'Magyar Agrár- és Élettudományi Egyetem',
    },
    {
      id: 'Magyar Képzőművészeti Egyetem (MKE)',
      name: 'Magyar Képzőművészeti Egyetem (MKE)',
    },
    {
      id: 'Magyar Táncművészeti Egyetem (MTE)',
      name: 'Magyar Táncművészeti Egyetem (MTE)',
    },
    { id: 'MATE Kaposvári Campus', name: 'MATE Kaposvári Campus' },
    { id: 'McDaniel College (MCDC)', name: 'McDaniel College (MCDC)' },
    {
      id: 'Milton Friedman Egyetem (MILTON)',
      name: 'Milton Friedman Egyetem (MILTON)',
    },
    { id: 'Miskolci Egyetem (ME)', name: 'Miskolci Egyetem (ME)' },
    {
      id: 'Moholy-Nagy Művészeti Egyetem (MOME)',
      name: 'Moholy-Nagy Művészeti Egyetem (MOME)',
    },
    {
      id: 'Nemzeti Közszolgálati Egyetem (NKE)',
      name: 'Nemzeti Közszolgálati Egyetem (NKE)',
    },
    { id: 'Neumann János Egyetem (NJE)', name: 'Neumann János Egyetem (NJE)' },
    { id: 'Nyíregyházi Egyetem (NYE)', name: 'Nyíregyházi Egyetem (NYE)' },
    { id: 'Óbudai Egyetem (OE)', name: 'Óbudai Egyetem (OE)' },
    {
      id: 'Országos Rabbiképző - Zsidó Egyetem (OR-ZSE)',
      name: 'Országos Rabbiképző - Zsidó Egyetem (OR-ZSE)',
    },
    { id: 'Pannon Egyetem (PE)', name: 'Pannon Egyetem (PE)' },
    {
      id: 'Pápai Református Teológiai Akadémia (PRTA)',
      name: 'Pápai Református Teológiai Akadémia (PRTA)',
    },
    {
      id: 'Pázmány Péter Katolikus Egyetem (PPKE)',
      name: 'Pázmány Péter Katolikus Egyetem (PPKE)',
    },
    {
      id: 'Pécsi Püspöki Hittudományi Főiskola (PPHF)',
      name: 'Pécsi Püspöki Hittudományi Főiskola (PPHF)',
    },
    { id: 'Pécsi Tudományegyetem (PTE)', name: 'Pécsi Tudományegyetem (PTE)' },
    {
      id: 'Pünkösdi Teológiai Főiskola (PTF)',
      name: 'Pünkösdi Teológiai Főiskola (PTF)',
    },
    {
      id: 'Sapientia Szerzetesi Hittudományi Főiskola (SSZHF)',
      name: 'Sapientia Szerzetesi Hittudományi Főiskola (SSZHF)',
    },
    {
      id: 'Sárospataki Református Teológiai Akadémia (SRTA)',
      name: 'Sárospataki Református Teológiai Akadémia (SRTA)',
    },
    { id: 'Semmelweis Egyetem (SE)', name: 'Semmelweis Egyetem (SE)' },
    {
      id: 'Sola Scriptura Teológiai Főiskola (SSTF)',
      name: 'Sola Scriptura Teológiai Főiskola (SSTF)',
    },
    { id: 'Soproni Egyetem (SOE)', name: 'Soproni Egyetem (SOE)' },
    {
      id: 'Széchenyi István Egyetem (SZE)',
      name: 'Széchenyi István Egyetem (SZE)',
    },
    {
      id: 'Szegedi Tudományegyetem (SZTE)',
      name: 'Szegedi Tudományegyetem (SZTE)',
    },
    {
      id: 'Szent Atanáz Görögkatolikus Hittudományi Főiskola (SZAGKHF)',
      name: 'Szent Atanáz Görögkatolikus Hittudományi Főiskola (SZAGKHF)',
    },
    { id: 'Szent Pál Akadémia (SZPA)', name: 'Szent Pál Akadémia (SZPA)' },
    {
      id: 'Színház- és Filmművészeti Egyetem (SZFE)',
      name: 'Színház- és Filmművészeti Egyetem (SZFE)',
    },
    { id: 'Testnevelési Egyetem (TE)', name: 'Testnevelési Egyetem (TE)' },
    {
      id: 'Tokaj-Hegyalja Egyetem (THE)',
      name: 'Tokaj-Hegyalja Egyetem (THE)',
    },
    { id: 'Tomori Pál Főiskola (TPF)', name: 'Tomori Pál Főiskola (TPF)' },
    {
      id: 'University of Buckingham (UB)',
      name: 'University of Buckingham (UB)',
    },
    {
      id: 'Veszprémi Érseki Főiskola (VÉF)',
      name: 'Veszprémi Érseki Főiskola (VÉF)',
    },
    {
      id: 'VISART Művészeti Akadémia (VISART)',
      name: 'VISART Művészeti Akadémia (VISART)',
    },
    {
      id: 'Wekerle Sándor Üzleti Főiskola (WSUF)',
      name: 'Wekerle Sándor Üzleti Főiskola (WSUF)',
    },
    {
      id: 'Wesley János Lelkészképző Főiskola (WJLF)',
      name: 'Wesley János Lelkészképző Főiskola (WJLF)',
    },
    { id: 'Egyéb', name: 'Egyéb' },
  ];

  constructor(
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    public emojiService: EmojiServiceService,
    private localeService: LocaleService,
    private mobileAppService: MobileAppService,
    private locationService: LocationService,
    private ngZone: NgZone
  ) {}

  dropNoty(type, text, timeout?) {
    this.noty = new Noty({
      type: type,
      text: text,
      killer: true,
      timeout,
    }).show();
  }

  joinGame() {
    this.pageConfig.loaders.joinGame = true;
    console.log(this.activeGame);

    if (this.activeGame.isDynamicQuestionCreation) {
      this.dropNoty(
        'info',
        this.localeService.getTrans('Checking location data') +
          '<i class="las la-circle-notch ml-1 la-spin"></i>'
      );
      this.locationService.getPosition().then(
        (x: any) => {
          this.ngZone.run(() => {
            if (x.coords) {
              this.coords = x.coords;
              this.isLocation = true;
              this.performJoin(true);
            }
            if (this.noty) {
              this.noty.close();
            }
          });
        },
        (err) => {
          this.isLocation = false;
          this.pageConfig.loaders.joinGame = false;
          Swal.fire({
            title: this.localeService.getTrans('Cannot access location'),
            text: this.localeService.getTrans(
              'You need to grant access to the browser to start the game.'
            ),
            icon: 'error',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: `Got it`,
            cancelButtonText: 'Back',
            heightAuto: false,
          });
        }
      );
    } else {
      this.performJoin(false);
    }
  }

  performJoin(hasLocation?) {
    console.log('perform join');
    return this.http
      .post(environment.baseUrl + 'games/joinGameWithQr', {
        tourId: this.params.tid,
        paymentIdentifier: this.params.pc,
        partner: this.params.uid,
        playerObj: this.playerObj,
        coords: hasLocation
          ? {
              lat: this.coords.latitude,
              lng: this.coords.longitude,
            }
          : null,
      })
      .subscribe((data: any) => {
        console.log('=====')
        console.log(data);
       
        if (data.success) {
          this.router.navigate([
            '/museum-walkthrough',
            {
              g: data.data._id,
              p: this.playerObj.teamName,
              partner: this.params.uid,
              showcase: this.params.showcase,
              qId: this.params.qId,
            },
          ]);
        } else {
          this.utilsService.dropSwal(
            'Oops',
            'Something went wrong or the QR was already used before.',
            'error'
          );
        }

        this.pageConfig.loaders.joinGame = false;
      });
  }

  setAvatar() {
    this.playerObj.avatarIndex = this.usefulSwiper.swiper.activeIndex;
  }

  setAvatarIndex() {
    this.playerObj.avatarIndex = this.usefulSwiper.swiper.activeIndex;
  }

  ngOnInit() {
    if (this.utilsService.checkDeviceCompatibility()) {
      this.route.queryParams.subscribe((params) => {
        console.log(params);
        this.params = params;
        if (!params.uid) {
          window.location.href = this.mobileAppService.getURL() + 'en/museums';
        }

        if (params.pc && params.uid && params.tid) {
          return this.http
            .post(environment.baseUrl + 'partnerPayments/getPartnerPayment', {
              tourId: params.tid,
              paymentIdentifier: params.pc,
              partner: params.uid,
            })
            .subscribe((data: any) => {
              console.log(data);
              if (!data.success) {
                window.location.href =
                  'https://jobverse.flinkit.io/hu/museum-gamepass/?uid=63ecad29917e7f8a06ba1934&locale=hu&tid='+params.tid+'&type=false&groupId=cce2840c-03c9-44a8-b17f-1f8970dc3f2dm&limit=0&validity=0';
                  //'http://localhost:4200/museum-gamepass/?uid=63ecad29917e7f8a06ba1934&locale=hu&tid='+params.tid+'&type=false&groupId=cce2840c-03c9-44a8-b17f-1f8970dc3f2dm&limit=0';
                
                 /*   Swal.fire({
                  icon: 'info',
                  text: data.msg,
                  showDenyButton: false,
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonText: `Got it`,
                  confirmButtonColor: '#4E3B74',
                }).then((result) => {
                  this.router.navigate(['/museum-onboarding'], {
                    queryParams: {
                      uid: params.uid,
                    },
                   });
                }) */
              } else {
                this.activeGame = data.tour;
                console.log(this.activeGame, 'ac');
                this.bgColor = this.activeGame.partner
                  ? this.activeGame.isCustomBranding &&
                    this.activeGame.customBranding.color
                    ? this.activeGame.customBranding.color
                    : this.activeGame.partner.color
                    ? this.activeGame.partner.color
                    : null
                  : null;
              }
            });
        }
      });
    }
  }

  validateEmail(mail) {
   // Adding \+ in the regex to allow + in the email
   if (/^\w+([\.-]?\w+)*(\+\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    this.emailError = null;
    return true;
}
this.emailError = 'Érvénytelen e-mail címet adott meg!';
return false;
  }
}
