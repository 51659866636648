import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-end-screen',
  templateUrl: './end-screen.component.html',
  styleUrls: ['./end-screen.component.scss']
})
export class EndScreenComponent implements OnInit {
  game;
  bgColor;
  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient) { }

  navigateBack() {
    this.router.navigate(['/']);
  }

  getGame(gameId, player){
    return this.http.post(environment.baseUrl + 'games/getGame', {gameId, player} ).subscribe((data: any) => {
     if(data.success){
       this.game = data.data;
       this.bgColor = this.game.partner ? ((this.game.tourId.isCustomBranding && this.game.tourId.customBranding.color) ? this.game.tourId.customBranding.color : (this.game.partner.color ? this.game.partner.color : null)) : null;

       console.log('am', this.game)
     }
   }); 
 }

 jumpToResults(){
    if (this.game.isMuseum) {
      window.location.href = "https://jobverse-admin.azurewebsites.net/jobverse";
    } else {
      window.location.href = "https://jobverse-admin.azurewebsites.net/jobverse";
    }

 }

  ngOnInit(): void {
    if (!this.route.snapshot.paramMap.get('g') || !this.route.snapshot.paramMap.get('p')) {
      this.navigateBack();
    } 
    if(this.route.snapshot.paramMap.get('g') && this.route.snapshot.paramMap.get('p')){
      this.getGame(this.route.snapshot.paramMap.get('g'), this.route.snapshot.paramMap.get('p'))
    }
   
  }

}
