import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MuseumOnboardingComponent } from './museum-onboarding.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    NgxUsefulSwiperModule,
    NgSelectModule
  ],
  declarations: [MuseumOnboardingComponent],
  exports: [MuseumOnboardingComponent]
})
export class MuseumOnboardingModule { }
