<div class="fi-modal-content-container d-flex align-items-center justify-content-center p-3" [ngClass]="{'image-padding':question.mediaUrl}">
  <!-- Standby -->
  <div class="row" *ngIf="modalState === 'standby'">
    <div class="col-12 text-center" >
      <img src=".././assets/images/question-marker.png" width="55" alt=""  data-aos="fade-down" >
      <h4 class="fi-text-black mt-4"  data-aos="fade-up" i18n >You've arrived to a question</h4>
      <h6 class="fi-text-medium fi-text-gray"  data-aos="fade-up" i18n >If you are ready press 'Next'</h6>
      <button class="flinkint-btn primary btn-block mt-3" [style.backgroundColor]="gameService.activeGame.partner ? gameService.activeGame.partner.color : null" [style.borderColor]="gameService.activeGame.partner ? gameService.activeGame.partner.color : null"  data-aos="fade-up" (click)="proceedToQuestion()"i18n  >Next</button>
    </div>
  </div>
  <!-- End of Standby -->
   <!-- Standby -->
   <div class="row" *ngIf="modalState === 'info'">
    <div class="col-12 text-center">
      <img src=".././assets/images/info.png" width="55" alt=""  data-aos="fade-down" >
      <h4 class="fi-text-black mt-4"  data-aos="fade-up" i18n >You've arrived to an information</h4>
      <h6 class="fi-text-medium fi-text-gray"  data-aos="fade-up" i18n >If you are ready press 'Next'</h6>
      <button class="flinkint-btn primary btn-block mt-3" [style.backgroundColor]="gameService.activeGame.partner ? gameService.activeGame.partner.color : null" [style.borderColor]="gameService.activeGame.partner ? gameService.activeGame.partner.color : null"  data-aos="fade-up" (click)="proceedToQuestion()"i18n  >Next</button>
    </div>
  </div>
  <!-- End of Standby -->
  <!-- Standby EndPOI -->
  <div class="row" *ngIf="modalState === 'endPoi'">
    <div class="col-12 text-center">
      <img src=".././assets/images/pois/sh.png" width="120" alt=""  data-aos="fade-down" >
      <h4 class="fi-text-black mt-4"  data-aos="fade-up" i18n >You've arrived to the safe house</h4>
      <h6 class="fi-text-medium fi-text-gray"  data-aos="fade-up" i18n >If you are ready press 'Next'</h6>
      <button class="flinkint-btn primary btn-block mt-3" [style.backgroundColor]="gameService.activeGame.partner ? gameService.activeGame.partner.color : null" [style.borderColor]="gameService.activeGame.partner ? gameService.activeGame.partner.color : null"  data-aos="fade-up" (click)="proceedToQuestion()"i18n  >Next</button>
    </div>
  </div>
  <!-- End of Standby EndPOI -->
    <!-- Standby Timeline EndPOI -->
    <div class="row" *ngIf="modalState === 'timelineEndPoi'">
      <div class="col-12 text-center">
        <img src=".././assets/images/flag.png" width="55" alt=""  data-aos="fade-down" >
        <h4 class="fi-text-black mt-4"  data-aos="fade-up" >{{localeService.getTrans("You've arrived to the last question")}}</h4>
        <h6 class="fi-text-medium fi-text-gray"  data-aos="fade-up" i18n >If you are ready press 'Next'</h6>
        <button class="flinkint-btn primary btn-block mt-3" [style.backgroundColor]="gameService.activeGame.partner ? gameService.activeGame.partner.color : null" [style.borderColor]="gameService.activeGame.partner ? gameService.activeGame.partner.color : null"  data-aos="fade-up" (click)="proceedToQuestion()"i18n  >Next</button>
      </div>
    </div>
    <!-- End of Timeline EndPOI -->
  <!-- Standby -->
  <div class="row w-100" *ngIf="modalState === 'question'">
    <div class="col-12 text-center">
      <img *ngIf="question.mediaUrl" [src]="question.mediaUrl" (load)="imageLoaded()" class="img-fluid" >
      <h6 class="fi-text-black mt-4"  data-aos="fade-up" >{{question.question}}</h6>
        <!-- Select type question -->
        <div class="fi-answer-option-container mt-5" *ngIf="question.questionType === 'options' || !question.questionType">
          <div class="fi-answer-option fi-text-black fi-border-radius-10" [ngClass]="{'active': (selectedAnswer && selectedAnswer._id === option._id)}" (click)="toggleAnswer(option)" *ngFor="let option of question.answers">
            {{option.answer}}
          </div>
        </div>
        <!-- End of Select type question -->
      <!-- Select type question -->
      <div class="fi-standalone-answer-container mt-5" *ngIf="question.questionType === 'text'">
        <form name="answerForm" (submit)="validateAnswer()" >
          <input type="text" [(ngModel)]="selectedAnswer" [ngModelOptions]="{standalone: true}" class="fi-text-black" i18n-placeholder placeholder="Enter answer" i18n>
        </form>
      </div>
      <!-- End of Select type question -->
<!--       <div *ngIf="isDoubleActive" class="fi-pill ml-auto mr-auto mt-2 d-flex align-items-center justify-content-center"><img src="./assets/images/emoji/slot.png" width="40" alt="" class="mr-2" i18n>Ne felejtsd, hogy a duplázás aktív!</div>
 -->
      <button class="flinkint-btn time-btn fill-btn btn-block mt-3 fi-text-black text-white" [style.backgroundColor]="gameService.activeGame.partner ? gameService.activeGame.partner.color : null" [style.borderColor]="gameService.activeGame.partner ? gameService.activeGame.partner.color : null" (click)="validateAnswer()" [disabled]="!selectedAnswer" [ngClass]="{'warning': (passedTime/question.time) > 0.7}">
          <div class="fill" [ngStyle]="{width: ((1 - (passedTime/question.time))*100) < 90 ? (((1 - (passedTime/question.time))*100) > 10 ? (((1 - (passedTime/question.time))*100) + '%') : '10%') : '90%'}"></div>
          <div class="content"><span i18n>Submit </span>({{question.time-passedTime}}s)</div>
        </button>
    </div>
  </div>
  <!-- End of Standby -->
    <!-- Standby -->
    <div class="row w-100" *ngIf="modalState === 'endPoiQuestion'">
      <div class="col-12 text-center">
        <h5 class="fi-text-black mt-4"  data-aos="fade-up" >{{question.description}}</h5>
        <div class="flinkit-pin-code-container d-inline-flex mt-3" >
          <div class="pin-input-container dark narrow mr-2" >
            <input type="text" pattern="\d*" maxlength="1" [(ngModel)]="endPoiAnswer[0]" (focus)="endPoiAnswer[0] = null"   placeholder="0">
          </div>
           <div class="pin-input-container dark narrow mr-2" mask="0" >
            <input type="text" pattern="\d*" maxlength="1" #pinDigit2 [(ngModel)]="endPoiAnswer[1]" (focus)="endPoiAnswer[1] = null"   placeholder="0">
          </div>
          <div class="pin-input-container dark narrow mr-2" mask="0">
            <input type="text" pattern="\d*" maxlength="1" #pinDigit3  [(ngModel)]="endPoiAnswer[2]" (focus)="endPoiAnswer[2] = null"  placeholder="0">
          </div>
        </div>
        <button class="flinkint-btn primary btn-block mt-3 fi-text-black text-white" [style.backgroundColor]="gameService.activeGame.partner ? gameService.activeGame.partner.color : null" [style.borderColor]="gameService.activeGame.partner ? gameService.activeGame.partner.color : null" (click)="validateAnswer()" i18n>Submit</button>
        <button class="flinkint-btn white btn-block mt-2 fi-text-black shadow" [style.color]="gameService.activeGame.partner ? gameService.activeGame.partner.color : null" (click)="closeModalWithoutEvent()" i18n>Close</button>
      </div>
    </div>
    <!-- End of Standby -->
  <!-- Standby -->
  <div class="row w-100" *ngIf="modalState === 'story'">
    <div class="col-12 text-center">
      <img *ngIf="question.mediaUrl" [src]="question.mediaUrl"  class="img-fluid" >
      <h5 class="fi-text-black mt-4"  data-aos="fade-up" >{{question.question}}</h5>

      <button class="flinkint-btn primary btn-block mt-3 fi-text-black text-white" (click)="closeModal()" >
          <div class="content" i18n>Got it</div>
        </button>
    </div>
  </div>
  <!-- End of Standby -->
  <!-- Standby -->
  <div class="row w-100" *ngIf="modalState === 'infoQuestion'">
    <div class="col-12 text-center">
      <img *ngIf="question.mediaUrl" [src]="question.mediaUrl"  class="img-fluid" >
      <h5 class="fi-text-black mt-4"  data-aos="fade-up" >{{question.title}}</h5>
      <p>{{question.information}}</p>
      <button class="flinkint-btn primary btn-block mt-3 fi-text-black text-white" (click)="closeModalWithInfo()" >
          <div class="content" i18n>Got it</div>
        </button>
    </div>
  </div>
  <!-- End of Standby -->
</div>