import { getLocalePluralCase } from '@angular/common';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
@Injectable({
    providedIn: 'root'
})
export class MobileAppService {
    isTest = false;
    gameId = '66d5aabbd250a4e9c2275bc6';
    constructor() { }

    getURL() {
        //return 'http://localhost:4200/';
        if (this.isTest) {
            return 'https://flinkit-mobil-app-staging.azurewebsites.net/';
        } else{
            return 'https://jobverse.flinkit.io/';

        }
    }

}