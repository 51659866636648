import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MobileAppService } from '../services/mobile-app.service';
import Swal from "sweetalert2";
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { LocaleService } from '../services/locale.service';
declare var detect;
@Component({
  selector: 'app-museum-gamepass',
  templateUrl: './museum-gamepass.component.html',
  styleUrls: ['./museum-gamepass.component.scss']
})
export class MuseumGamepassComponent implements OnInit {
  params;
  constructor(private route: ActivatedRoute, private mobileAppService: MobileAppService, private localeService: LocaleService, private http: HttpClient) { }

  generatePassCode() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  isValidBrowser(){

    if(detect){
      let ua = detect.parse(navigator.userAgent);
      
      let isSafari = ua.browser.family.indexOf('Safari') === -1 ? false : true;
      let isChrome = ua.browser.family.indexOf('Chrome') === -1 ? false : true;
      let isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/i);
      if(isSamsungBrowser){
        this.dropErrorBrowser();
        return false;
      } else{
        if(isChrome || isSafari){
          return true;
        } else{
          this.dropErrorBrowser();
          return false;
        }
      }
    } else{
     return true;
    }  
  }

  dropErrorBrowser(){
    Swal.fire({
      imageUrl: '../assets/images/walkthrough/6a.png',
      title: this.localeService.getTrans('Not supported browser'),
      text: this.localeService.getTrans("The game only supports Chrome or Safari mobile browsers. "),
      showDenyButton: false,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: this.localeService.getTrans(`Got it`),
      confirmButtonColor: '#4E3B74'
    }).then((result) => {
       this.isValidBrowser();
    });
  }

  dropMaximumNumberOfPlayersError(){
    Swal.fire({
      imageUrl: '../assets/images/walkthrough/6a.png',
      title: this.localeService.getTrans('Maximum number of players reached'),
      text: this.localeService.getTrans("Maximum number of players reached"),
      showDenyButton: false,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: this.localeService.getTrans(`Got it`),
      confirmButtonColor: '#4E3B74'
    });
  }



  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      console.log(params);
      this.params = params;
      if (!params.uid) {
        window.location.href = this.mobileAppService.getURL();
      }
   
        if (params.uid && params.tid && params.type && params.groupId && params.limit) {
          if(this.isValidBrowser()){
            this.http.post(environment.baseUrl + 'games/countGamesByGroupId', {
              groupId: params.groupId
            }
          ).subscribe((data: any) => {
            if((data.msg >= params.limit) && params.limit > 0){
              this.dropMaximumNumberOfPlayersError();
            } else{

              console.log(params)
              let url = this.mobileAppService.getURL() + params.locale + '/museum-landing?uid=' + params.uid + '&pc=' + this.generatePassCode() + '&tid=' + params.tid + '&groupId=' + params.groupId + '&type=' + params.type + (params.qId ? '&qId=' + params.qId : '');
              //let url = 'http://localhost:4200/' + 'museum-landing?uid=' + params.uid + '&pc=' + this.generatePassCode() + '&tid=' + params.tid + '&groupId=' + params.groupId + '&type=' + params.type + (params.qId ? '&qId=' + params.qId : '');
            
             
              /* let url =
                this.mobileAppService.getURL() +
                '/museum-landing?uid=' +
                params.uid +
                '&pc=' +
                this.generatePassCode() +
                '&tid=' +
                params.tid +
                '&groupId=' +
                params.groupId +
                '&type=' +
                params.type;
              */
              if(params.showcase){
                url += '&showcase=true';
              }
              window.location.href = url;
            }
          });
          
          }
        }

      });
    
  }

}
