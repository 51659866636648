import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionModalComponent } from './questionModal.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [QuestionModalComponent],
  exports: [QuestionModalComponent]
})
export class QuestionModalModule { }
