<!-- Map container  (click)="mapClicked($event)" -->
<div id="fi-map-container" *ngIf="gameService.activeGame && (gameService.activeGame.tourId.isQR ? activeBounds?.length : true)">
  <div id="egg" *ngIf="activePerks.blank_map">
    <img src="./assets/images/egg.png" alt="">
  </div>
  <mgl-map (load)="map = $event" [movingMethod]="'jumpTo'" [fitBounds]="gameService.activeGame.tourId.isQR ? activeBounds : undefined" (click)="mapClicked($event)" [style]="mapStyle" [minZoom]="6" [maxZoom]="21" [zoom]="pageConfig.zoom" >
    
    <mgl-image-source *ngFor="let layer of activeLayers"
    id="museum_layer_{{layer.url}}"
    type="image"
    [url]="layer.url"
    [coordinates]="layer.coordinates"></mgl-image-source>

    <mgl-layer *ngFor="let layer of activeLayers"
    [id]="'layer_'+layer.url"
    type="raster"
    [source]="'museum_layer_'+layer.url"
  ></mgl-layer>


    <mgl-layer
    id="state-borders"
    type="line"
    [pitch]="[30]"
    [source]="gameService.battleCircle"
    [paint]="{
      'line-color': '#627BC1',
      'line-width': 20
    }"
  ></mgl-layer>



    <!-- Me marker -->
    <mgl-marker *ngIf="gameService.me && gameService.me.position" [lngLat]="[gameService.me.position.lng, gameService.me.position.lat]">
      <div class="fi-map-marker-me" [ngStyle]="{'background': 'url(./assets/images/pois/'+ (localeService.locale ? localeService.locale : 'en')+'.png)',
      'background-size': '50px',
      'background-repeat': 'no-repeat'}">
      </div>
    </mgl-marker>
    <!-- End of Me marker -->

      <!-- End of Enemy marker -->
        <!-- Question marker -->
        <div *ngIf="gameService.activeGame.tourId.isQR else normalMarker">
        
          <mgl-marker *ngFor="let quest of getGameQuests()" (click)="dropTooltip('./assets/images/pois/question-marker.png', 'Question','Answering questions earns you  '+quest.points+' points. Approach it to activate.')" [lngLat]="[quest.lng, quest.lat]">
           
            <div [class]="gameService.activeGame.tourId.isQR ? ( quest.isQuestion ? 'fi-map-marker-question qr' : 'fi-map-marker-question info-marker' ) : 'fi-map-marker-question question-marker'">
            </div>
          </mgl-marker>
        </div>
        <ng-template #normalMarker>
          <mgl-marker *ngFor="let quest of getGameQuests()" (click)="dropTooltip('./assets/images/pois/question-marker.png', 'Question','Answering questions earns you  '+quest.points+' points. Approach it to activate.')" [lngLat]="[quest.lng, quest.lat]">
            <div [class]="gameService.activeGame.tourId.isQR ? 'fi-map-marker-question qr' : (quest.isQuestion ? (quest.isEndPoi ? 'fi-map-marker-question finish-marker' : 'fi-map-marker-question question-marker') : 'fi-map-marker-question info-marker' )">
            </div>
          </mgl-marker>
        </ng-template>
        
        <!-- End of Questions marker -->
        
      <!-- Enemy marker -->
     <!--  <div *ngFor="let enemyMarker of gameService.mapPlayers">
        <mgl-marker class="text-center d-flex justify-content-center" *ngIf="enemyMarker && enemyMarker.lng && enemyMarker.lat"  [lngLat]="[enemyMarker.lng, enemyMarker.lat]">
          <div>
            <div class="fi-map-marker-enemy bg-2">
              <img [src]="emojiService.getEmojiByIndex(enemyMarker.avatarIndex).icon" width="30" alt="">
            </div>
            <div class="flinkit-text-small fi-text-bold w-100 text-center">{{enemyMarker.name}}</div>
          </div>
       
        </mgl-marker>
      </div> -->
     
    
  

  
    
  </mgl-map>
  <div id="fi-map-header">
    <div class="row">
      <div class="col-3"></div>
      <div class="col-6">

      </div>
      <!--  <div class="col-3 text-center">
        <div class="fi-header-circle-btn shadow ml-auto mr-auto d-flex align-items-center justify-content-center">
          <img [src]="emojiService.getEmojiByIndex(me.avatarIndex).icon" width="35" alt="">
        </div>

        <div class="fi-header-circle-btn shadow ml-auto mr-auto d-flex align-items-center justify-content-center mt-3">
          <img [src]="serviceGuard.signalIcon" width="35" alt="">
        </div>
      </div> -->
    </div>
  </div>
  <!-- Map controls -->
  <div id="fi-map-control-container">

   <!--   <button class="fi-map-control shadow" [disabled]="pageStatus.maxZoom === pageStatus.zoom" [ngClass]="{'active': pageStatus.maxZoom === pageStatus.zoom}" (click)="zoom(true)"><i class="las la-plus"></i></button>
    <button class="fi-map-control shadow mt-1"  [disabled]="pageStatus.minZoom === pageStatus.zoom" [ngClass]="{'active': pageStatus.minZoom === pageStatus.zoom}" (click)="zoom(false)"><i class="las la-minus"></i></button>
    <button class="fi-map-control shadow mt-1" [ngClass]="{'active': !pageStatus.followMe}" (click)="toggleFollowMeMode()"><i class="las la-crosshairs"></i></button> -->
    <button class="fi-map-control d-flex align-items-center justify-content-center shadow mt-1" [style.background-color]="bgColor1" [style.border-color]="bgColor1"  data-toggle="tooltip" data-placement="left" title="Tooltip on right" *ngIf="gameService.activeGame.tourId.isQR && gameService.activeGame.partner.raster_image_url.length > 1" (click)="toggleLevels()">{{activeLevel}}</button> 
<!--     <button class="fi-map-control d-flex align-items-center justify-content-center shadow mt-1" [style.background-color]="bgColor1" [style.border-color]="bgColor1"   (click)="dropWalkthrough()"><i class="las la-question"></i></button> 
 -->    <button class="fi-map-control d-flex align-items-center justify-content-center shadow mt-1" [style.background-color]="bgColor1" [style.border-color]="bgColor1"  *ngIf="gameService.activeGame && gameService.activeGame.partner && !gameService.activeGame.partner.isMuseum" (click)="refresh()"><i class="las la-sync"></i></button> 
    <button class="fi-map-control d-flex align-items-center justify-content-center shadow mt-1" [style.background-color]="bgColor1" [style.border-color]="bgColor1"  *ngIf="!gameService.activeGame.tourId.isQR" (click)="locateMe()"><i class="las la-map-marker"></i></button> 
  </div>

  <!-- End of Map controls -->
  <!-- Divider -->
  <div class="position-absolute bottom-pos-0" style="margin-bottom: -1px; z-index: 999; width:100%;">
    <img src="./assets/images/divider.png" class="img-fluid w-100" alt="">
  </div>
  <!-- End of Divider -->
  <!-- Scroll Divider -->
  <button class="position-absolute map-refresh-btn flinkint-btn primary pl-5 pr-5 mt-1 animatebutton" style="z-index: 999;" (click)="scanQR()" *ngIf="this.gameService.activeGame.tourId.isQR">
    <span i18n>Scan QR code</span> 
  </button>

  <button class="position-absolute map-bomb-active flinkint-btn primary  mt-1" style="z-index: 999;" *ngIf="activePerks.bomb">
    <img width="30" alt="" src="./assets/images/v3/bomb.svg"><span>{{localeService.getTrans("Bomb perk active. Half points at next question.")}}</span>
  </button>

 <!--  <button class="position-absolute map-refresh-btn flinkint-btn primary pl-5 pr-5 mt-1 animatebutton" style="z-index: 999;" (click)="refresh()" [disabled]="!geoService.canLookup" *ngIf="gameService.activeGame && gameService.activeGame.partner && !gameService.activeGame.partner.isMuseum" >
    <span i18n>Refresh</span> 

  </button> -->

  <!-- End of Scroll Divider -->
</div>
<!-- End of Map container -->
<!-- Map container -->
<div id="fi-map-content-container"  *ngIf="gameService.me && gameService.activeGame">

  <!-- Main indicators -->
  <div class="row mt-3 mr-0 ml-0">
    <div class="text-center" [class]="gameService.activeGame.tourId.isGameTimer ? 'col-12' : 'col-12'" >
      <h1 class="fi-text-bold fi-text-huge" [ngClass]="{'fi-text-huge-2' : (gameService.me.points) >= 1000}">{{gameService.me.points}}</h1>
      <h6 class="fi-text-medium fi-text-gray" i18n>points</h6>
    </div>
    
  </div>
  <!-- End of Main indicators -->
  <!-- AR / Normal game changer -->
  <!--<div class="row mt-3 mr-0 ml-0">
    <div class="col-12">
      <div class="fi-toggle-button d-inline-flex">
        <div class="flex-fill fi-text-bold" [ngClass]="{'active': pageStatus.mode === 'ar'}" (click)="changeMapMode('ar')">AR</div>
        <div class="flex-fill fi-text-bold" [ngClass]="{'active': pageStatus.mode === 'map'}" (click)="changeMapMode('map')">Térkép</div>
      </div>
    </div>
  </div>-->
  <!-- End of AR / Normal game changer -->
    <!-- Backpack -->
   <!--  <div id="backpacks" class="row mt-4 mr-0 ml-0" *ngIf="gameService.activeGame && gameService.activeGame.tourId.isStory && !gameService.isPartnerGame">
      <div class="col-12">
        <h5 class="fi-text-bold" i18n>Backpack</h5>
      </div>
       <div class="col-12">
        <swiper [config]="swiperConfig" #usefulSwiper class="mt-3" *ngIf="(gameService.me && gameService.me.backpack.length) else emptyBackpack">
          <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let item of gameService.me.backpack" (click)="clickedBackpack(item)">
              <div class="fi-game-card gray d-flex align-items-center justify-content-center">
                <div class="w-100 p-2">
                  <img [src]="item" class="img-fluid" alt="">
                </div>
              </div>
            </div>

          </div>
        </swiper>
        <ng-template #emptyBackpack>
          <div class="row">
            <div class="col-12 pt-5 pb-5 d-flex align-items-center justify-content-center">
              <div class="text-center">
                <img src="./assets/images/pois/backpack.png" width="120">
                <h5 class="fi-text-bold" i18n>Backpack is empty</h5>
              </div>
             
            </div>
          </div>
        </ng-template>
      </div>
    </div> -->
    <!-- End of Backpack -->
  <!-- Perks -->
  <!-- Input -->

<!--   <input type="file" id="camcorder" (change)="uploadImages($event)" style="display: none;" accept="image/*" capture="user">
 -->  <!-- End of Input -->
   <!-- HOF -->
   <div class="row mt-4 mr-0 ml-0" *ngIf="gameService.activeGame && gameService.activeGame.players">
    <div class="col-6">
      <h5 class="fi-text-bold text-truncate" i18n>Players</h5>
    </div>
    <div class="col-6 text-right">
     <!--   <h6 class="fi-text-bold text-truncate"> <span i18n>Your rank:</span> {{gameService.me.ranking+1}}.</h6> -->
  </div>
    <div class="col-12 table-responsive pr-0 pl-0 mt-3" *ngIf="!gameService.activeGame.isMuseum else museumSwiperBlock">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" i18n>Rank</th>
            <th scope="col" i18n>Player</th>
            <th scope="col" i18n>Points</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let player of getRankingMapPlayers() trackBy: points; let i = index" (click)="scrollToContainer('fi-map-container', player)">
            <td class="align-middle">{{i+1}}.</td>
            <td class="align-middle" scope="row"><div class=" text-truncate"><img [src]="emojiService.getEmojiByIndex(player.avatarIndex).icon" class="mr-1 mb-1" width="20"
              alt="">{{player.name}}</div></td>
            <td class="align-middle"><div class="fi-text-bold text-truncate">{{player.points}} <span i18n>points</span></div></td>
          </tr>
          
        </tbody>
      </table>
    
    </div>
    <ng-template #museumSwiperBlock>
      <div class="col-12 pr-0 pl-0">
        <swiper [config]="{
      spaceBetween: 10,
      loop: false,
      slidesPerView: 'auto',
      initialSlide:1,
      loopedSlides: 2,
      centeredSlides: true
    }" #usefulSwiper class="mt-3">
          <div class="swiper-wrapper fi-players-swiper">
            <div class="swiper-slide" *ngFor="let player of museumPlayers trackBy: points;">
              <div class="fi-game-card bg-3 d-flex align-items-center justify-content-center" [style.background-color]="bgColor1">
                <div class="w-100 p-2">
                  <div class="text-white fi-text-bold text-truncate">{{player.name}} 
                  
                  </div>
                  <h1 class="mt-4 mb-4"><img [src]="emojiService.getEmojiByIndex(player?.avatarIndex).icon" width="60"
                      alt=""></h1>
                  <h5 class="text-white fi-text-bold text-truncate mb-1" >{{player.points}} <span i18n>points</span></h5>
<!--                   <div class="d-flex align-items-center w-100 justify-content-center" *ngIf="!player.isFinished"><div class="inMuseum" i18n>Active</div></div>
 -->                </div>
              </div>
            </div>
          </div>
        </swiper>
      </div>
    </ng-template>
  </div>
 <div class="row mt-4 mr-0 ml-0" *ngIf="gameService.activeGame">
    <div class="col-12">
      <h5 class="fi-text-bold">Készíts fotót!</h5>
    </div>
     <div class="col-12">
      <swiper [config]="swiperConfig" #usefulSwiper class="mt-3">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="fi-game-card d-flex align-items-center justify-content-center" (click)="togglePerk(gifModal,{type: 'stand', points: 0})">
              <div class="w-100 p-2">
                <div class="text-white fi-text-bold text-truncate text-capitalize">Kedvenc <br/> Stand</div>
                <h1 class="mt-4 mb-4 fi-text-huge"><img [src]="'./assets/images/v3/stall.png'" width="70" alt=""></h1>
              </div>
            </div>
          
          </div>
          <div class="swiper-slide">
            <div class="fi-game-card d-flex align-items-center justify-content-center" (click)="togglePerk(gifModal,{type: 'jobverse', points: 0})">
              <div class="w-100 p-2">
                <div class="text-white fi-text-bold text-truncate text-capitalize">JOBVERSE <br/> Élmény</div>
                <h1 class="mt-4 mb-4 fi-text-huge"><img [src]="'./assets/images/v3/party.png'" width="70" alt=""></h1>
              </div>
            </div>
          
          </div>

          <div class="swiper-slide">
            <div class="fi-game-card d-flex align-items-center justify-content-center" (click)="togglePerk(gifModal,{type: 'astronaut', points: 0})">
              <div class="w-100 p-2">
                <div class="text-white fi-text-bold text-truncate text-capitalize">JOBVERSE <br/> Asztronauta</div>
                <h1 class="mt-4 mb-4 fi-text-huge"><img [src]="'./assets/images/v3/astronaut.png'" width="70" alt=""></h1>
              </div>
            </div>
          
          </div>
        
        </div>
      </swiper>
    </div>
  </div>
  <!-- End of Perks -->

  <div class="row mt-4 mr-0 ml-0" *ngIf="messageHistory && messageHistory.length">
    <div class="col-12">
      <h5 class="fi-text-bold text-truncate" i18n>Messages</h5>
    </div>
  
    <div class="col-12 table-responsive pr-0 pl-0 mt-3">
      <div class="fi-chat-panel">
        <div class="body">
          <div class="mine mb-3"  *ngFor="let message of messageHistory">
            <div class="message mb-0">
              <div class="text" >
                <p class="mb-0 pb-0">{{message.message.adminMessage}}</p>
              </div>
            </div>
            <div class="text-right">
              <small class="text-muted">{{message.time}}</small>
            </div>
         
          
          </div>
          
        </div>
      </div>
    
    </div>
 
  </div>
 
  <!-- End of HOF -->
  <!-- Game history -->
  <div class="container">
    <div class="row mt-4 mr-0 ml-0" id="messagesContainer">
      <div class="col-12 mt-3">
<!--         <button class="flinkint-btn primary btn-block mt-1" [style.background-color]="bgColor1" [style.border-color]="bgColor1" (click)="dropWalkthrough()" i18n>How it works</button>


 -->        
 <a class="flinkint-btn primary btn-block mt-1" href="https://jobverse-admin.azurewebsites.net/jobverse" target="_blank">Eredmények</a>
 <button class="flinkint-btn danger btn-block mt-1" (click)="endGameDirectly()" i18n>Játék befejezése</button>

 <button class="flinkint-btn danger btn-block mt-1" (click)="logout()">Kijelentkezés</button>

      </div>
    </div>
    <div class="row text-center mt-3" *ngIf="!gameService.activeGame.partner.isMuseum && gameService.activeGame && gameService.activeGame.partner && gameService.activeGame.partner.isExclusiveBranded">
      <div class="col-12 text-center fi-text-bold">
        <img [src]="gameService.activeGame.tourId.isCustomBranding ? gameService.activeGame.tourId.customBranding.cover : ((gameService.activeGame.partner && gameService.activeGame.partner.logo) ? gameService.activeGame && gameService.activeGame.partner.logo : './assets/images/pbe.png')"  width="90"alt="">
      </div>
    </div>
    <div class="row text-center mt-3" *ngIf="gameService.activeGame.partner.isMuseum && gameService.activeGame.partner.isDigitalMuseum && gameService.activeGame && gameService.activeGame.partner && gameService.activeGame.partner.isExclusiveBranded">
      <div class="col-12 text-center fi-text-bold">
        <img [src]="gameService.activeGame.tourId.isCustomBranding ? gameService.activeGame.tourId.customBranding.cover : ((gameService.activeGame.partner && gameService.activeGame.partner.logo) ? gameService.activeGame && gameService.activeGame.partner.logo : './assets/images/pbe.png')"  width="90"alt="">
      </div>
    </div>
    <div class="row text-center mt-3" *ngIf="gameService.activeGame.partner.isMuseum && !gameService.activeGame.partner.isDigitalMuseum ">
      <div class="col-12 text-center fi-text-bold">
        <img src="./assets/images/pbe.png"  width="90"alt="">
      </div>
    </div>
  </div> 
 
  <!-- End of Game history -->
</div>
<!-- End of Map container -->

<!-- Freeze modal -->
<div id="fl-freeze-modal" class="text-center" *ngIf="gameService.interimSettings.freezeActive">
  <div>
    <h1 class="mb-4 fi-text-huge"><img src="./assets/images/emoji/ice.png" width="70" alt=""></h1>
    <h5 class="fi-text-bold text-truncate"><span i18n>Somebody frozed all the</span> <br> <span i18n>players for 30 seconds.</span></h5>
  </div>
</div>
<!-- End of Freeze modal -->
<!-- QR Modal -->
<div id="qr_overlay" *ngIf="pageConfig.showQrReader">
  <div class="close shadow" (click)="closeQR()">X</div>
  <zxing-scanner #scanner start="true" [device]="currentDevice" (scanSuccess)="handleQrCodeResult($event)" [formats]="['QR_CODE']"></zxing-scanner>
</div>
<!-- End of QR Modal -->
<!-- GIF modal -->
<ng-template #gifModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" i18n>Choose a gif</h4>
  </div>
  <div class="modal-body">
    <form>
      <div class="mb-3">
          <div class="row">
            <div class="col-6" *ngFor="let gif of availableGifs">
              <img [src]="gif.url" width="200" height="200" alt="">
            </div>
          </div>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #camCorderModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n>
      {{localeService.getTrans('Take a photo')}}
    </h4>
  </div>
  <div class="modal-body">
      <div class="mb-3">
          <div class="row">
            <div class="col-12">
                <div id="previewContainer" class="text-center">
                  <img [src]="dynamic_url" class="img-fluid" id="dynamic_img" *ngIf="dynamic_url else imgPlaceHolder" />
                  <ng-template #imgPlaceHolder>
                    <img src="./assets/images/v3/photo.svg" alt="">
                    <h6 class="text-muted">
                      {{localeService.getTrans('No image selected')}}
                    </h6>
                  </ng-template>
                </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <label for="camcorder" class="flinkint-btn primary btn-block mt-1">
                {{localeService.getTrans('Take a photo')}}
            </label>
              <input type="file" id="camcorder" style="display: none;" (change)="uploadImages($event)" accept="image/*" capture="user">
              <button type="button" class="flinkint-btn success btn-block mt-1" [disabled]="!previewImage" (click)="uploadImage()">
                {{localeService.getTrans('Upload image')}}
              </button>
            </div>
          </div>
      </div>
  </div>
</ng-template>