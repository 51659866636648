<div class="flinkit-full-bg white" *ngIf="game">
  <div class="row mr-0 ml-0 fi-walkthrough-container pt-5 pb-5">
    <div class="col-12 d-flex align-items-center justify-content-center text-center">
      <div class="w-100">
        <!-- Logo container -->
        <div class="text-center" *ngIf="partner">
          <img [src]="game && game.tourId && game.tourId.isCustomBranding ? game.tourId.customBranding.cover : (game.partner && game.partner.customLogo ? game && game.partner.customLogo : './assets/images/museums/logo_b.png')" class="img-fluid-30" alt="">
         <!--  <img [src]="partner && partner.customLogo ? partner.customLogo : './assets/images/museums/logo_b.png'"
            class="img-fluid-80" alt=""> -->
        </div>
        <!-- End of Logo container -->
        <swiper #usefulSwiper [config]="swiperConfig" class="mt-5">
          <div class="swiper-wrapper align-items-center">
            <!-- Swiper -->
            <div class="swiper-slide">
              <img src="./assets/images/walkthrough/7a.png" alt="">
              <h5 class="fi-text-bold mt-3">Üdvözlünk</h5>
              <p>A JOBVERSE GO by Flinkit! a JOBVERSE Állásbörze ingyenes kalandjátéka, melyben játéktérré válik a rendezvény helyszíne.
                Válaszolj helyesen a kérdésekre, és gyűjtsd be a legtöbb pontot, hogy te lehess a legügyesebb kódvadász! </p>
            </div>
            <!-- End of Swiper -->
             <!-- Swiper -->
             <div class="swiper-slide">
              <img src="./assets/images/walkthrough/2.png" alt="">
              <h5 class="fi-text-bold mt-3">A játék célja</h5>
              <p>Legyél az első 150 között, aki eléri a minimum 100 pontot, és vedd át a garantált BURN vagy Nissin ajándékcsomagod az Infopultnál! Végezz a TOP5 hely egyikén, hogy tiéd legyen az 50.000 Ft értékű Lidl vásárlási utalvány vagy a Skullcandy Rail True Wireless fülhallgatók egyike, vagy akár egy 25.000 Ft értékű kozmetikai ajándékcsomag!</p>
            </div>
            <!-- End of Swiper -->
              <!-- Swiper -->
            <div class="swiper-slide">
              <img src="./assets/images/walkthrough/8.png" alt="">
              <h5 class="fi-text-bold mt-3">Kérdések és feladványok</h5>
              <p>Hozz létre egy avatárt, és kezdd meg a QR kódok levadászását az interaktív térkép segítségével! Extra pontokért keresd a térképen kívül elhelyezett QR kódokat, melyeket a rendezvény több pontján is elrejtettünk!</p>
            </div>
            <!-- End of Swiper -->
            
 
            <!-- Swiper -->
            <div class="swiper-slide">
              <img src="./assets/images/walkthrough/10.png" alt="">
              <h5 class="fi-text-bold mt-3">Jó, ha tudod...</h5>
              <p>A játék a rendezvény mindkét napján elérhető.
                Psszt!
                Képfeltöltésért bónuszpontokat is szerezhetsz.</p>
              <button class="flinkint-btn primary btn-block mt-3"  [style.background-color]="bgColor" [style.border-color]="bgColor"   (click)="joinGame()" i18n>I'm ready to start</button>
            </div>
            <!-- End of Swiper -->
          </div>
          <div class="swiper-pagination"></div>
        </swiper>
      </div>
    </div>
  </div>
</div>