<div id="museumBg1">
  <div class="overlay">
    <div class="container p-3">
      <!-- Header -->
      <div class="row">
        <div class="col-6">
          <img src="./assets/images/logo_museums.png" width="120" alt="">
        </div>
        <div class="col-6 d-flex justify-content-end">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-light fl-text-bold"  id="dropdownBasic1" ngbDropdownToggle><img src="https://flinkitother.blob.core.windows.net/images/{{iconLocale}}.png" width="40"></button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem (click)="switchLanguage('hu')" class="d-flex align-items-center"><img src="https://flinkitother.blob.core.windows.net/images/hu.png" class="mr-1" width="20">Hungarian</button>
              <button ngbDropdownItem (click)="switchLanguage('en')"><img src="https://flinkitother.blob.core.windows.net/images/uk.png" class="mr-1" width="20">English</button>
              <button ngbDropdownItem (click)="switchLanguage('de')"><img src="https://flinkitother.blob.core.windows.net/images/de.png" class="mr-1" width="20">German</button>
            </div>
          </div>
       
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12">
          <h1 class="text-white fs-bold fi-text-black">{{localeService.getTrans('Felfedezős kalandjáték a mobilodon itt és most.')}}</h1>
        </div>
      </div>
      <div class="row mt-5" *ngIf="partnerGames && partnerGames.length">
        <div class="col-12">
          <h5 class="text-white fi-text-bold">{{localeService.getTrans('Játékok')}}</h5>
        </div>
        <div class="col-12">
          <div class="card mb-3 shadow-lg" style="border-radius: 20px;" [class.active]="game.isSelected" *ngFor="let game of partnerGames; let i = index;" (click)="selectTour(game)">
            <div class="card-body">
              <h5 class="mb-2 d-flex align-items-center">{{game.name}}</h5> 
              <p class="text-muted fi-text-08">{{game.description}}</p>

              <div class="d-flex align-items-center">
                <h6 class="fi-text-bold d-flex align-items-center mr-3"><img src="./assets/images/v3/money.svg" width="25"> &nbsp;{{partner.currency}}&nbsp;{{game.price}}</h6>
                <h6 class="fi-text-bold d-flex align-items-center"><img src="./assets/images/v3/star.svg" width="25"> &nbsp;4.8</h6>
              </div>
       

            </div>
          </div>
        </div>
      </div>
     
      <div class="row pb-5 mt-5">
        <div class="col-12 text-center">
          <h4 class="text-yl fi-text-bold fw-bold">{{localeService.getTrans('Mire számítsak?')}}</h4>
        </div>
        <div class="col-12 pb-5">
          <swiper #usefulSwiper [config]="swiperConfig">
            <div class="swiper-wrapper align-items-center">
              <!-- Swiper -->
              <div class="swiper-slide text-center">
                <img src="./assets/images/v3/map.svg" width="100" alt="">
              
                <h5 class="fi-text-bold mt-3 text-white">{{localeService.getTrans('A játék')}}</h5>
                  <p class="text-white mb-4">{{localeService.getTrans('A játék során számos izgalmas kérdés és feladat által fedezed fel a helyszínt, és megismered titkait. A játék meglepetéseket is tartogat...')}}  </p>
                </div>
              <!-- End of Swiper -->
               <!-- Swiper -->
               <div class="swiper-slide text-center">
                <img src="./assets/images/v3/vaze.svg" width="100" alt="">
                <h5 class="fi-text-bold mt-3 text-white">{{localeService.getTrans('Pontok')}}</h5>
                <p class="text-white mb-4">{{localeService.getTrans('Szerezz minél több pontot! Ehhez a térképen megjelölt helyszínekre kell eljutnod, ill. a kihelyezett QR kódokat beolvasnod.')}}</p>
              </div>
              <!-- End of Swiper -->
               <!-- Swiper -->
               <div class="swiper-slide text-center">
                <img src="./assets/images/v3/ticket.svg" width="100" alt="">
                <h5 class="fi-text-bold mt-3 text-white">{{localeService.getTrans('A játék indítása')}}</h5>
                <p class="text-white mb-4">{{localeService.getTrans('Válaszd ki a játékot és kattints a "Tovább a biztonságos fizetésre" gombra. A játék díját bankkártyával tudod kifizetni. Fizetést követően a játék azonnal indul. Kalandra fel!')}}</p>
              </div>
              <!-- End of Swiper -->
           
              
            </div>
            <div class="swiper-pagination white"></div>
          </swiper>
        </div>
        <div class="col-12  text-center">
          <p class="pb-0 text-white mb-0"><small class="text-white">{{localeService.getTrans('Lépj kapcsolatba velünk:')}}</small></p>
          <p class="pb-2 text-yl"><small><a href="mailto:info@flinkit.io" class="text-yl">info@flinkit.io</a> | <a href="mailto:ugyfelszolgalat@flinkit.io" class="text-yl">ugyfelszolgalat@flinkit.io</a></small></p>
        </div>
          <div class="col-12 pb-5 text-center">
            <p class="pb-2 text-white"><small i18n>{{localeService.getTrans('A legteljesebb játékélményhez Chrome és Safari böngészők használata ajánlott.')}}</small></p>
          </div>
       
      </div>
      <div class="paymentBlock">
        <button class="btn btn-primary btn-lg fi-text-bold" [disabled]="!selectedTour" (click)="checkout()"><i class="la la-lock mr-1"></i>{{localeService.getTrans('Tovább a biztonságos fizetésre')}}</button>
      </div>
    </div>

  </div>
</div>


<!-- <div id="museumBg" *ngIf="partner">
  <div class="d-flex justify-content-center text-center pt-5 w-100">
    <img [src]="partner  && partner.customLogo ? partner.customLogo : './assets/images/museums/logo_b.png'" width="180" alt="">
  </div>
  <div class="museum-leaderboard p-3">
    <div class="leaderboard">
      <div class="row mt-3">
        <div class="col-12 text-center">
          <h4 class="fi-text-black mb-1">{{localeService.getTrans('Available interactive games')}}</h4>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-center">
          <div class="fi-text-black mb-1">{{localeService.getTrans('Switch language')}}</div>
       
        </div>
        <div class="col-6 offset-3 text-center mt-2 mb-2">
          <img src="https://flinkitother.blob.core.windows.net/images/hu.png" (click)="switchLanguage('hu')" class="mr-3" width="40">
          <img src="https://flinkitother.blob.core.windows.net/images/uk.png" (click)="switchLanguage('en')" class="mr-3" width="40">
          <img src="https://flinkitother.blob.core.windows.net/images/de.png" (click)="switchLanguage('de')" width="40">
         
        </div>
        
      </div>
      
      <div class="row mt-3" *ngIf="partnerGames">
        <div class="col-12">
          <div class="fi-result-row p-3 shadow d-flex align-items-center mb-2" *ngFor="let game of partnerGames; let i = index;" >
            <div class="text-left w-100">
              <h4 class="mb-2 fi-text-bold d-flex align-items-center">{{game.name}}</h4> 
              <h6>💵 &nbsp;{{partner.currency}}&nbsp;{{game.price}}<span *ngIf="game.length">&nbsp;· ⏱ &nbsp;{{game.length}}&nbsp;<span i18n>mins</span></span></h6>
              <p class="text-muted fi-text-08">{{game.description}}</p>
              <button class="btn btn-block btn-primary w-100" [disabled]="loaders" (click)="checkout(game)" [style.border-color]="partner ? partner.color : null" [style.backgroundColor]="partner ? partner.color : null"><span i18n>Purchase</span>&nbsp;({{partner.currency}}&nbsp;{{game.price}})</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3 pb-5">
        <div class="col-12 text-center">
          <img  src="./assets/images/pbe.png" width="80" alt="">
          <p class="p-2 text-muted"><small i18n>A játék kizárólag Chrome és <br> Safari böngészőkben játszható.</small></p>

        </div>
      </div>
    </div>
   
  </div>
</div> 
 -->