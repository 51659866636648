import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '../services/utils.service';
import Swal from 'sweetalert2';
import { EmojiServiceService } from '../services/emoji-service.service';
import { LocaleService } from '../services/locale.service';
import * as _ from 'lodash';
import { SwiperComponent } from 'ngx-useful-swiper';
import { MobileAppService } from '../services/mobile-app.service';
declare var Stripe;
@Component({
  selector: 'app-museum-purchase',
  templateUrl: './museum-purchase.component.html',
  styleUrls: ['./museum-purchase.component.scss'],
})

export class MuseumPurchaseComponent implements OnInit {
  @ViewChild('usefulSwiper',{static: true}) usefulSwiper: SwiperComponent;
  partner;
  stripe;
  partnerGames = [];
  loaders = true;
  selectedLanguage;
  selectedTour;
  checkoutObj = {
    tour: null
  };
  iconLocale = 'uk';
  swiperConfig = {
    loop: false,
    slidesPerView: 'auto',
    centeredSlides: true,
    autoplay: {
      delay: 4000,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
  };
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private utilsService: UtilsService,
    public localeService: LocaleService,
    public emojiService: EmojiServiceService,
    private mobileAppService: MobileAppService
  ) {}

  switchLanguage(locale){
    let localeString = '/'+locale+'/';
    let redirectString = this.mobileAppService.getURL()+locale+'/' + window.location.href.substr(window.location.href.indexOf('museum-purchase'),window.location.href.length);
    window.location.href = redirectString;
  }

  getPartnerGames(){
    return this.http
    .post(environment.baseUrl + 'tours/getPartnerTours', {
      partner: this.partner._id,
    })
    .subscribe((data: any) => {
      console.log(data);
      if (data.success) {
        this.loaders = false;
        this.partnerGames =  _.map(_.filter(data.data, (p)=>{return p.price !== 0}), (tour)=>{
          return _.merge(tour, {isSelected: false});
        });
        this.selectTour(this.partnerGames[0]);
      }
    });
  }

  logVisit(partner, type){
    return this.http.post(environment.baseUrl + 'logs/createLog', {
     partner: partner._id,
     type
    })
    .subscribe((data: any) => {
      console.log(data)
    });
  }
  getPartnerDetails(_id, qrLog) {
    return this.http
      .post(environment.baseUrl + 'partners/getPartner', {
        _id,
      })
      .subscribe((data: any) => {
        console.log(data);
        if (data.success) {
          this.partner = data.data;
          console.log(this.partner)
          this.getPartnerGames();
          this.logVisit(this.partner, qrLog ? 1 : 0)

        } else {
          window.location.href = this.mobileAppService.getURL()+'museums';
        }
      });
  }

  selectTour(tour){
    if(this.selectedTour === tour){
      tour.isSelected = false;
      this.selectedTour = null;
    } else{
      tour.isSelected = true;
      this.selectedTour = tour;
    }
    
  }


  checkout(){

    Swal.fire({
      imageUrl: '../../assets/images/v3/stripe.png',
      title: this.localeService.getTrans('Checkout'),
      text: this.localeService.getTrans('Now we will redirect you to the payment page.'),
      /* input: 'checkbox',
      inputPlaceholder: 'Elfogadom az Általános Szerződési Feltételeket és az Adatkezelési Nyilatkozatot',
      inputLabel: 'Your IP address',
      inputValidator: (value) => {
        if (!value) {
          return 'You need to write something!'
        }
      }, */
      showDenyButton: false,
      position: 'bottom',
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: this.localeService.getTrans('Back'),
      confirmButtonText: this.localeService.getTrans('Next'),
      confirmButtonColor: '#007bff'
    }).then((result) => {
      this.loaders = true;
      if (result.isConfirmed) {
        this.checkoutObj.tour = this.selectedTour._id;
        return this.http.post(environment.baseUrl + 'payments/checkoutSessionDirect', {checkoutObj: this.checkoutObj} ).subscribe((data: any) => {
          if(data){
            this.logVisit(this.partner, 2);
            this.stripe.redirectToCheckout({ sessionId: data.data.id });
          } else{
            this.loaders = false;
          }
        });
      } else{
        this.loaders = false;
      }
    })
      
        
  }

  detectLocale(){
    if(window.location.href.indexOf('/hu/') !== -1){
      this.localeService.locale = 'hu';
      this.iconLocale = 'hu';
    } else if(window.location.href.indexOf('/de/') !== -1){
      this.localeService.locale = 'de';
      this.iconLocale = 'de';
    } else{
      this.localeService.locale = 'en';
      this.iconLocale = 'uk';
    }
  }

  ngOnInit() {
    this.stripe = Stripe(environment.stripe);
    if (this.utilsService.checkDeviceCompatibility()) {
      this.route.queryParams.subscribe((params: any) => {
      
        console.log(params);
       
       window.location.href = 'https://jobverse.flinkit.io/hu/museum-gamepass?uid=64c0dfcd149d77c4b872a836&locale=hu&tid='+this.mobileAppService.gameId+'&type=false&groupId=cce2840c-03c9-44a8-b17f-1f8970dc3f2dm&limit=0&qId=' + params.qId ?? null;
       //window.location.href = 'http://localhost:4200/museum-gamepass?uid=64c0dfcd149d77c4b872a836&locale=hu&tid='+this.mobileAppService.gameId+'&type=false&groupId=cce2840c-03c9-44a8-b17f-1f8970dc3f2dm&limit=0&qId=' + params.qId ?? null;

      });
    }
    this.detectLocale();
  }
}
