import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  pageSettings = {
    type: null
  }
  
  constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient) {
    console.log(this.route.snapshot.paramMap.get('t'))
    console.log(this.route.snapshot.paramMap.get('interimPId'))
    if (this.route.snapshot.paramMap.get('t') && this.route.snapshot.paramMap.get('interimPId')) {
      this.pageSettings.type = this.route.snapshot.paramMap.get('t');
  
      if(this.pageSettings.type === 's'){
         this.http.post(environment.baseUrl + 'payments/processInterimPayment', {_id: this.route.snapshot.paramMap.get('interimPId')} ).subscribe((data: any) => {
          console.log(data)
           if(data.success){

            if(data.data.isMuseumPayment){
              window.location.href = data.data.url;
            } else{
             /*  return this.http.post(environment.baseUrl + 'tours/generateTour', {tourId: data.data.checkoutObj.tour, checkoutObj: data.data.checkoutObj} ).subscribe((data: any) => {
                console.log(data)
                 this.router.navigate(['/success', {g: data.data._id, p: data.data.pin.toString()}]);
              }); */
            }
           
          } else{
            this.navigateBack();
          }
        
        })
      } else{
        setTimeout(()=>{
          this.router.navigate(['/']);
        },4000)
      }
    } else {
      this.navigateBack();
    }
  }


  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  navigateBack() {
    this.router.navigate(['/']);
  }

  ngOnInit() {}
}
