
  <!-- Step 1 -->
  <div class="flinkit-section-bg" *ngIf="activeGame">
    <div class="flinkit-section-bg">
      <div class="flinkit-new-page-content-section">
        <div class="upper" [style.background-color]="bgColor">
          <div class="content">
            <!-- Logo container -->
            <div class="text-center text-white">
<!--               <img [src]="activeGame && activeGame.partner && activeGame.partner.customLogo ? activeGame && activeGame.partner.customLogo : './assets/images/museums/logo_b.png'" class="img-fluid-80" alt="">
 -->              <img [src]="activeGame && activeGame.isCustomBranding ? activeGame.customBranding.cover : (activeGame.partner && activeGame.partner.customLogo ? activeGame && activeGame.partner.customLogo : './assets/images/logo_b.png')" width="140" alt="">

            </div>
            <!-- End of Logo container -->
            <div class="row mt-3 text-center">
              <div class="col-12" data-aos="fade-up" data-aos-delay="100">
                <h6 class="flinkit-event-text">{{activeGame.name}}</h6>
              </div>
            </div>
          </div>
          <!-- Divider-->
          <div class="flinkit-divider">
            <img src="./assets/images/divider.png" class="img-fluid" alt="">
          </div>
          <!-- End of Divider-->
        </div>
  
        <div class="bottom d-flex align-items-center justify-content-center">
          <div class="w-75">
            <!-- Step 1 -->
             <div class="form-group"  data-aos="fade-left" data-aos-delay="200" *ngIf="pageConfig.loginStep === 0">
              😎 &nbsp;<label class="fi-text-bold">{{localeService.getTrans('Player name')}}</label>
              <input type="text" [(ngModel)]="playerObj.teamName" autofocus [maxlength]="20" placeholder="Player name" i18n-placeholder class="form-control fi-form-control">
              <small class="flinkit-text-small fi-text-bold text-danger" *ngIf="playerObj &&  playerObj.teamName && playerObj.teamName.length < 5 ">{{5-playerObj.teamName.length}} <span i18n>characters more</span></small>
              <div class="form-group has-error" *ngIf="error">
                <small class="help-block bg-danger-alt text-danger">{{error}}</small>
              </div>
              <div class="form-group">
                <small class="help-block bg-danger-alt"><i class="las la-info-circle "></i><span i18n>This will be your player name in the game</span></small>
              </div>
              <label class="fi-text-bold">{{localeService.getTrans('Email address')}}</label>
              <input type="email" [(ngModel)]="playerObj.email" autofocus  placeholder="Email" i18n-placeholder class="form-control fi-form-control">
              <div class="form-group has-error" *ngIf="emailError">
                <small class="help-block bg-danger-alt text-danger">{{emailError}}</small>
              </div>
            
              <label class="fi-text-bold mt-3">{{localeService.getTrans('Institution')}}</label>
              <ng-select [items]="institutions" 
                  bindLabel="name" 
                  bindValue="id" 
                  [clearable]="false"
                  [(ngModel)]="playerObj.selectedInstitution">
              </ng-select>

             <!--  <label for="question" class="fi-text-bold mb-2 mt-3"> <ng-container i18n>{{localeService.getTrans('Color code')}}</ng-container></label>
              <ng-select [searchable]="false" [clearable]="false" [(ngModel)]="playerObj.colorCode">
                <ng-option [value]="'#A6A6A6'">
                  <div class="d-flex align-items-center fi-text-08">
                    <div class="color-selector small color1 me-2"></div>&nbsp;Mérnök, Műszaki terület
                  </div>
                </ng-option>
                <ng-option [value]="'#00BAEE'">
                  <div class="d-flex align-items-center fi-text-08">
                    <div class="color-selector small color2 me-2"></div>&nbsp;Informatikai terület
                  </div>
                </ng-option>
                <ng-option [value]="'#FF7723'">
                  <div class="d-flex align-items-center fi-text-08">
                    <div class="color-selector small color3 me-2"></div>&nbsp;Pénzügy, Számvitel, Logisztika terület
                  </div>
                </ng-option>
                <ng-option [value]="'#FFD630'">
                  <div class="d-flex align-items-center fi-text-08">
                    <div class="color-selector small color4 me-2"></div>&nbsp;Kereskedelem/Értékesítés, Marketing, HR terület
                  </div>
                </ng-option>
                <ng-option [value]="'#AC58E5'">
                  <div class="d-flex align-items-center fi-text-08">
                    <div class="color-selector small color5 me-2"></div>&nbsp;Adminisztráció, Ügyfélszolgálat terület
                  </div>
                </ng-option>
                <ng-option [value]="'#F9232C'">
                  <div class="d-flex align-items-center fi-text-08">
                    <div class="color-selector small color6 me-2"></div>&nbsp;Szakirányú végzettséget igénylő további területek
                  </div>
                </ng-option>
                <ng-option [value]="'#68C83E'">
                  <div class="d-flex align-items-center fi-text-08">
                    <div class="color-selector small color7 me-2"></div>&nbsp;Kiállító vagyok
                  </div>
                </ng-option>
              </ng-select> -->
            
              
       

              <button class="flinkint-btn primary btn-block mt-3" [style.border-color]="bgColor" [style.background-color]="bgColor" [disabled]="!playerObj.teamName || playerObj.teamName.length < 5 || !playerObj.email || !validateEmail(playerObj.email) || !playerObj.selectedInstitution " (click)="pageConfig.loginStep = 1"  data-aos="fade-left" i18n>Next</button>
  
            </div>
            <!-- End of Step 1 -->
            <!-- Step 1 -->
             <div class="form-group"  data-aos="fade-left"  *ngIf="pageConfig.loginStep === 1">
              <h4 class="fi-text-bold text-center"><span i18n>Hello </span> <span class="fi-text-primary">{{playerObj.teamName}}! </span> <span i18n> Set an avatar!</span></h4>
              <swiper [config]="swiperConfig"  #usefulSwiper class="mt-3">
                <div class="swiper-wrapper d-flex align-items-center">
                  <div class="swiper-slide emojis" *ngFor="let emoji of emojiService.getEmojis(); let i = index" (click)="setAvatarIndex(i)"><img [src]="emoji.icon" alt=""></div>
                </div>
              </swiper>
              <div class="row mt-2">
                <div class="col-12 text-center">
                  <div class="arrow-up ml-auto mr-auto"></div>
                </div>
              </div>
              <button class="flinkint-btn primary btn-block mt-3" [style.border-color]="bgColor" [style.background-color]="bgColor" [disabled]="pageConfig.loaders.joinGame" (click)="setAvatar(); joinGame()">
                <span *ngIf="pageConfig.loaders.joinGame else loaderBlock"><i class="las la-circle-notch la-2x la-spin"></i></span>
                <ng-template #loaderBlock> <span i18n>Start game</span></ng-template>
              </button>
              <div class="row mt-1 text-center">
                <div class="col-12">
                  <button class="flinkint-btn primary outline btn-block mt-1" [style.color]="bgColor" [style.border-color]="bgColor" (click)="pageConfig.loginStep = 0" i18n>Back</button>
                </div>
              </div>
            </div>
            <!-- End of Step 1 -->
            <div class="row text-center">
              <div class="col-12 text-center fi-text-bold">
                <!-- <img [src]="activeGame.isCustomBranding ? activeGame.customBranding.cover : (activeGame.partner.isDigitalMuseum ? activeGame.partner.logo : './assets/images/pbe.png')" width="90" alt=""> -->

                 <img [src]="activeGame.partner.isWhiteLabel ? activeGame.partner.logo  :'./assets/images/pbe.png'" width="90" alt="">

<!--                 <img src="./assets/images/pbe.png" width="90" alt=""> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </div>
