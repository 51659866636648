import { BrowserModule } from '@angular/platform-browser';
import { NgModule,ErrorHandler,APP_INITIALIZER  } from '@angular/core';
import { Router } from "@angular/router";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PinModule } from './pin/pin.module';
import { GameModule } from './game/game.module';
import { LandingModule } from './landing/landing.module';
import { CheckoutModule } from './checkout/checkout.module';
import { PurchaseSuccessModule } from './purchase-success/purchase-success.module';
import { QuestionModalModule } from './components/questionModal/questionModal.module';
import { EndGameModule } from './endGame/endGame.module';
import { WalkthroughModule } from './components/walkthrough/walkthrough.module';
import { WalkthroughModalModule } from './components/walkthroughModal/walkthroughModal.module';
import { PurchaseErrorModule } from './purchase-error/purchase-error.module';
import { PaymentModule } from './payment/payment.module';
import { MuseumLandingModule } from './museum-landing/museum-landing.module';
import { MuseumOnboardingModule } from './museum-onboarding/museum-onboarding.module';
import { MuseumWalkthroughModule } from './museum-walkthrough/museum-walkthrough.module';
import { MuseumPurchaseComponent } from './museum-purchase/museum-purchase.component';
import { MuseumPurchaseModule } from './museum-purchase/museum-purchase.module';
import { MuseumLeaderboardModule } from './museum-leaderboard/museum-leaderboard.module';
import * as Sentry from "@sentry/angular";
import { MuseumsComponent } from './museums/museums.component';
import { MuseumsModule } from './museums/museums.module';
import { RaygunErrorHandler } from './app.raygun.setup';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { StartScreenModule } from './start-screen/start-screen.module';
import { EndScreenModule } from './end-screen/end-screen.module';

@NgModule({
  declarations: [
    AppComponent,
    OnboardingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PinModule,
    GameModule,
    LandingModule,
    CheckoutModule,
    PurchaseSuccessModule,
    QuestionModalModule,
    EndGameModule,
    PaymentModule,
    WalkthroughModule,
    WalkthroughModalModule,
    PurchaseErrorModule,
    MuseumsModule,
    MuseumLandingModule,
    MuseumOnboardingModule,
    MuseumWalkthroughModule,
    MuseumPurchaseModule,
    MuseumLeaderboardModule,
    StartScreenModule,
    EndScreenModule
  ],
  providers: [
     /* {
    provide: ErrorHandler,
    useClass: RaygunErrorHandler
  }  */
],
  bootstrap: [AppComponent]
})
export class AppModule { }
